import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import style from "./DatePicker.module.css";

const DatePicker = ({ fromDate = moment().toDate(), toDate = moment().toDate(), setFromDate, setToDate, important }) => {
  const range = {
    All: [moment().subtract(100, "years"), moment().add(100, "years")],
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
    "This Year": [moment().startOf("year").startOf("day"), moment().endOf("year").endOf("day")],
    "Last Year": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate ? picker.startDate.toDate() : moment().subtract(100, "years").toDate());
    setToDate(picker.endDate ? picker.endDate.toDate() : moment().add(100, "years").toDate());
  };

  function showInButton(range, from, to) {
    let ret;
    Object.entries(range).forEach((item) => {
      let result;
      const rangeFrom = item[1][0];
      const rangeTo = item[1][1];

      if (rangeFrom.isSame(moment(from), "day") && rangeTo.isSame(moment(to), "day")) {
        result = item;
      }
      if (result) {
        ret = result[0].toString();
      }
    });

    if (ret) {
      return ret;
    } else if (moment(from).isSame(moment().subtract(100, "years"), "day") && moment(to).isSame(moment().add(100, "years"), "day")) {
      return "All";
    } else {
      return `${moment(from).format("LL")} to ${moment(to).format("LL")}`;
    }
  }

  return (
    <>
      <DateRangePicker startDate={moment(fromDate)} endDate={moment(toDate)} ranges={range} alwaysShowCalendars={false} onEvent={handleEvent}>
        <button className={"text-secondary bg-white text-capitalize border-secondary " + style.button}>{showInButton(range, fromDate, toDate)}</button>
      </DateRangePicker>
    </>
  );
};

export default DatePicker;
