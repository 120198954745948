import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { toast } from "react-toastify";
const AddSpotsModal = ({ toggle, close, bookingId }) => {
  const addSpots = useApi(postapi.addSpots, { hasCatchError: true });
  const fetchSpot = useApi(api.fetchSpotById, { hasCatchError: true });
  const updateSpot = useApi(postapi.updateSpot, { hasCatchError: true });
  const [spot, setSpot] = useState({
    place: "",
    total_spots: "",
    price: "",
    spot_weight: "",
  });
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    if (bookingId) {
      getSpot();
    }
  }, [bookingId]);
  const getSpot = async () => {
    try {
      const res = await fetchSpot.request(bookingId);
      if (res.status === 200) {
        const bookingId = res.data.success.data[0];
        setSpot({
          place: bookingId.place,
          total_spots: bookingId.total_spots,
          price: bookingId.price,
          spot_weight: bookingId.spot_weight,
        });
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
  };
  const handleChange = (e) => {
    setSpot((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSpot = async (e) => {
    try {
      e.preventDefault();
      // if (bookingId) {
      //   if (spot.place !== "" && spot.total_spots !== "" && spot.price !== "" && spot.spot_weight !== "") {
      //     setValidated(true);
      //     const res = await updateSpot.request({
      //       id: bookingId,
      //       place: spot.place,
      //       total_spots: +spot.total_spots,
      //       price: +spot.price,
      //       spot_weight: +spot.spot_weight,
      //     });
      //     if (res.status === 200) {
      //       toast.success(res.data.message, {
      //         position: toast.POSITION.BOTTOM_RIGHT,
      //         autoClose: 1500,
      //         pauseOnHover: false,
      //         className: "toastMsg",
      //       });
      //       toggle();
      //       setSpot({ place: "", total_spots: "", available_spots: "", price: "", spot_weight: "" });
      //       close();
      //     }
      //   }
      // } else {
      if (spot.place !== "" && spot.total_spots !== "" && spot.price !== "" && spot.spot_weight !== "") {
        setValidated(true);
        if (bookingId) {
          const res = await updateSpot.request({
            id: bookingId,
            place: spot.place,
            total_spots: +spot.total_spots,
            price: +spot.price,
            spot_weight: +spot.spot_weight,
          });
          if (res.status === 200) {
            toggle();
            toast.success(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });

            setSpot({ place: "", total_spots: "", price: "", spot_weight: "" });
            close();
          }
        } else {
          const res = await addSpots.request({
            place: spot.place,
            total_spots: +spot.total_spots,
            price: +spot.price,
            spot_weight: +spot.spot_weight,
          });
          if (res.status === 201) {
            toast.success(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
            toggle();
            setSpot({ place: "", total_spots: "", price: "", spot_weight: "" });
            close();
          }
        }
        if (addSpots.error) {
          setError(addSpots.error.data.error.message);
        }
      }
      // }
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSpot}>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <Row className="">
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Place<span className="text-danger">*</span>
              </label>
              <Form.Control placeholder="Enter Place" name="place" value={spot.place} onChange={(e) => handleChange(e)} maxLength={50} />
              <Form.Control.Feedback type="invalid">* Please Enter Place</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Total Spots</label>
              <Form.Control
                value={spot.total_spots}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="total_spots"
                onChange={(e) => {
                  handleChange(e);
                }}
                min={1}
                type="number"
                placeholder="eg.10"
              />
              <Form.Control.Feedback type="invalid">Please Enter Total Spots.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Price</label>
              <Form.Control
                value={spot.price}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="price"
                onChange={(e) => {
                  handleChange(e);
                }}
                min={1}
                type="number"
                placeholder="eg.100"
              />
              <Form.Control.Feedback type="invalid">Please Enter Price.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Spot weight</label>
              <Form.Control
                value={spot.spot_weight}
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                name="spot_weight"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const value = inputValue === "" ? "" : Math.max(1, Math.min(9, Number(inputValue)));
                  setSpot((prev) => ({ ...prev, spot_weight: value }));
                }}
                min={1}
                max={9}
                type="number"
                placeholder="eg.10"
              />
              <Form.Control.Feedback type="invalid">Please Enter Spots weight.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <Button className="w-100" disabled={!spot.place || !spot.total_spots || !spot.price || !spot.spot_weight} type="submit" variant="primary">
              {bookingId ? "Update" : "Save"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddSpotsModal;
