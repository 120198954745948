import React, { useEffect, useState } from "react";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Form, Row } from "react-bootstrap";
import customloader from "../../assets/images/RollingLoader.gif";

const FeaturedBookingPaymentModal = ({ onClose, requestId, toggle }) => {
  const [request, setRequest] = useState({});
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const [error, setError] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paidAmount, setPaidAmount] = useState();
  const [transDate, setTransDate] = useState(new Date().toISOString().split("T")[0]);
  const [transactionId, settransactionId] = useState();
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectImage, setSelectImag] = useState();
  const [selectImageURL, setSelectImagURL] = useState({
    url: "",
    type: "file",
  });
  const { user } = useSelector((state) => state.auth);
  const [isClicked, setIsClicked] = useState(false);
  const imageUpload = useApi(postapi.UPloadImage);
  const getBookingRequestById = useApi(api.getBookingRequestById, { hasCatchError: true });
  const payFeaturedListingsBookingRequest = useApi(postapi.payFeaturedListingsBookingRequest, { hasCatchError: true });
  const approveFeaturedListingsBookingRequest = useApi(postapi.approveFeaturedListingsBookingRequest, { hasCatchError: true });

  useEffect(() => {
    const fetchReq = async () => {
      try {
        setIsLoading(true);
        const res = await getBookingRequestById.request(requestId);
        const data = res.data.success.data;
        setRequest(res.data.success.data);
        setPaymentType(data.trxData[0]?.payment_method ?? "");
        setPaidAmount(data.trxData[0]?.trx_amount ?? 0);
        settransactionId(data.trxData[0]?.trx_id);
        setTransDate(data.trxData[0]?.trx_datetime.split("T")[0] ?? new Date().toISOString().split("T")[0]);
        setSelectImagURL(data.trxData[0]?.trx_proof ? { url: data.trxData[0]?.trx_proof, type: "url" } : { url: "", type: "file" });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error.response.data.error.message);
      }
    };
    fetchReq();
  }, [requestId]);
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (request.trxData.length > 0) {
      try {
        const res = await approveFeaturedListingsBookingRequest.request(requestId);
        if (res) {
          toast.success(res?.data?.success?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onClose();
          toggle((prev) => !prev);
        }
      } catch (error) {
        setError(error.response.data.error.message);
      }
    } else {
      if (paymentType === "Cash") {
        setTransDate();
        setSelectImag();
        setSelectImagURL();
        settransactionId();
      }
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (request.shop_name && paidAmount && paymentType && (paymentType === "Bank Transfer" || paymentType === "Card Payment" ? transactionId && transDate && selectImageURL : true)) {
        if (+paidAmount !== +request.booking_amount) {
          setPaidCheck(true);
        } else {
          try {
            let imgData;
            setValidated(true);
            if (selectImage) {
              imgData = await imageUpload.request(selectImage);
            }

            const [date, time] = new Date().toISOString().split("T");
            const curDate = date.split("-");
            const curTime = time.split(".")[0].replaceAll(":", "");
            const dateB32 = Number(curDate[2] + curDate[1] + curDate[0])
              .toString(32)
              .toUpperCase();
            const timeB32 = Number(curTime).toString(32).toUpperCase();
            const dateNow = new Date().toISOString().split("T")[0];
            const timeNow = new Date().toLocaleTimeString().split(" ")[0];
            const currentDate = dateNow + " " + timeNow;
            if (!paidAmount) {
              setIsClicked(false);
            } else {
              setIsClicked(true);
              let obj = {};
              if (request.trxData.length === 0) {
                obj = {
                  trx_amount: +paidAmount,
                  trx_proof: imgData ? imgData.data.files[0] : null,
                  trx_datetime: transDate ? transDate : currentDate,
                  trx_id: transactionId ? transactionId : requestId + user.user_id + dateB32 + timeB32,
                  payment_type: paymentType,
                };
              }
              const res = await payFeaturedListingsBookingRequest.request(requestId, obj);
              if (res) {
                toast.success("Request Approved Successfully", {
                  position: "bottom-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setIsClicked(false);
                setValidated(false);
                onClose();
                toggle((prev) => !prev);
              }
            }
          } catch (error) {
            setIsClicked(false);
            setError(error.response.data.error.message);
          }
        }
      }
    }
  };

  const handleImageUpload = async (e) => {
    const formData = new FormData();
    formData.append("files", e.target.files[0], e.target.files[0].name);
    setSelectImag(formData);
    setSelectImagURL({ url: URL.createObjectURL(e.target.files[0]), type: "file" });
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <Form noValidate validated={validated} onSubmit={handlePaymentSubmit}>
          {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}
          <Row>
            <>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Shop Name<span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2 text-capitalize" name="shop_name" placeholder="Shop" type="text" value={request?.shop_name} onChange={() => {}} required disabled />
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Amount Payable <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(request?.booking_amount)} type="number" placeholder="Rs.1000" disabled />
              </Form.Group>

              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                <label>Payment Type</label>
                <Form.Select
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  value={paymentType}
                  required
                  disabled={request?.trxData.length !== 0 ? true : false}
                >
                  <option value="" selected disabled>
                    Select Payment Type
                  </option>
                  <option value="Cash">Cash</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Card Payment">Card Payment</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please Select Payment Type</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Paid Amount <span className="text-danger">*</span>
                </label>
                <Form.Control
                  value={paidAmount ? paidAmount : ""}
                  placeholder="Rs.1000"
                  required
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  min={Number(request.booking_amount)}
                  max={Number(request.booking_amounte)}
                  onChange={(e) => {
                    if (Number(e.target.value) > 0) {
                      setPaidAmount(e.target.value);
                      if (Number(request.booking_amount) === Number(e.target.value)) {
                        setPaidCheck(false);
                      } else {
                        setPaidCheck(true);
                      }
                    } else {
                      setPaidAmount(0);
                    }
                  }}
                  disabled={request?.trxData.length !== 0}
                />

                <Form.Control.Feedback type="invalid">Please Enter Amount</Form.Control.Feedback>
              </Form.Group>
              {(paymentType === "Bank Transfer" || paymentType === "Card Payment") && (
                <>
                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Id <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      value={transactionId}
                      name="transaction_id"
                      onChange={(e) => {
                        settransactionId(e.target.value);
                      }}
                      placeholder="eg.AA11"
                      required={paymentType === "Bank Transfer" || paymentType === "Card Payment" ? true : false}
                      disabled={request?.trxData.length !== 0}
                    />
                    <Form.Control.Feedback type="invalid">Please Enter Transaction Id.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mt-1" as={Col} lg="6" md="6" sm="12" controlId="formBasicShopName">
                    <label>
                      Transaction Date<span className="text-danger">*</span>
                    </label>
                    <Form.Control value={transDate} name="trans_date" disabled={true} required={paymentType === "Bank Transfer" || paymentType === "Card Payment" ? true : false} type="date" />

                    <Form.Control.Feedback type="invalid">Please select Date.</Form.Control.Feedback>
                  </Form.Group>

                  {selectImageURL.type !== "url" ? (
                    <Form.Group className="mt-1" as={Col} lg="12" md="12" sm="12" controlId="formBasicShopName">
                      <label className="mb-1">
                        Transaction Proof<span className="text-danger">*</span>
                      </label>
                      <Form.Control type="file" size="sm" accept="image/*" name="contact_person_name" onChange={(e) => handleImageUpload(e)} required={paymentType === "Bank Transfer" || paymentType === "Card Payment" ? true : false} />
                      <Form.Control.Feedback type="invalid">Please upload image.</Form.Control.Feedback>
                      <div className="mt-2 d-flex align-items-center justify-content-center w-100 ">
                        <div style={{ width: "150px" }}>
                          <img src={selectImageURL.url} alt="" width="100%" height="auto" />
                        </div>
                      </div>
                    </Form.Group>
                  ) : (
                    <div className="mt-2 d-flex align-items-center justify-content-center w-100 ">
                      <div style={{ width: "150px" }}>
                        <img src={process.env.REACT_APP_URL_S3 + "/" + selectImageURL.url} alt="" width="100%" height="auto" />
                      </div>
                    </div>
                  )}
                </>
              )}

              <Button className="w-100 mt-3" type="submit">
                Approve
              </Button>
              {paidCheck && (
                <div class="alert alert-danger  text-center w-100 mt-2" role="alert">
                  Paid amount must be equal to payable
                </div>
              )}
            </>
          </Row>
        </Form>
      )}
    </>
  );
};

export default FeaturedBookingPaymentModal;
