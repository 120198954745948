import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import moment from "moment/moment";
import NoResult from "../../Components/NoResult";
import customloader from "../../assets/images/RollingLoader.gif";
import DatePicker from "../../Components/DatePicker/DatePicker";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import { useEffect } from "react";
import axios from "axios";
import PopUpModal from "../../Components/Modal";
import ReactSelect from "../../Components/ReactSelect";

const SellerRegistrationRequests = () => {
  let [registrationRequests, setRegistrationRequests] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "ASC" });
  const [searchInput, setSearchInput] = useState("");
  const [city, setCity] = useState(null);
  const [citiesOption, setCitiesOption] = useState([]);
  const [fromDate, setFromDate] = useState(moment().subtract(100, "years")._d);
  const [toDate, setToDate] = useState(moment().add(100, "years")._d);
  const [status, setStatus] = useState(null);
  const [showModeartorModal, setShowModeratorModal] = useState(false);
  const [Salesman, setSalesman] = useState();
  const [RegistrationId, setRegistrationId] = useState();
  const [filterInput, setFilterInput] = useState("".replace(/\W/g, ""));
  const SalesManCall = useApi(api.getSalesman);
  const AssignToCall = useApi(postapi.AssignTo);
  const fetchAllSellerRegistrationRequestsCall = useApi(api.fetchAllSellerRegistrationRequests);
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const handleStatus = (e) => {
    if (e.label === "All Requests") {
      setStatus(null);
    } else {
      setStatus({ value: e.value, label: e.label });
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(100, "years")._d);
    setToDate(moment().add(100, "years")._d);
    setCity(null);
    setStatus(null);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const getCities = async () => {
    try {
      const data = await axios.post("https://countriesnow.space/api/v0.1/countries/cities", {
        country: "Pakistan",
      });
      setCitiesOption(
        data.data.data.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } catch (error) {}
  };
  useEffect(() => {
    getCities();
  }, []);
  const fetchSellerRegistrationRequests = async () => {
    const res = await fetchAllSellerRegistrationRequestsCall.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      filters: {
        orderBy: orderBy,
        dates: { from: fromDate, to: toDate },
        city: city?.label,
        status: status?.label,
      },
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setRegistrationRequests(data?.data?.requests);
      setCount(data?.data?.pagination?.no_of_items);
      if (data?.data?.pagination?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.data?.pagination?.no_of_pages);
        if (currentPage > data?.data?.pagination?.no_of_pages) {
          setCurrentPage(data?.data?.pagination?.no_of_pages);
        }
      }
    } else {
      setRegistrationRequests([]);
    }
  };
  useEffect(() => {
    fetchSellerRegistrationRequests();
  }, [currentPage, refresh, sizeOfPages, searchInput, fromDate, toDate, toggle, city, orderBy, status]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const filterCities = (e) => {
    if (e.label === "none") {
      setCity({});
    } else {
      setCity({ value: e.value, label: e.label });
    }
  };
  const assignTo = (data) => {
    setRegistrationId(data["id"]);
    setShowModeratorModal(true);
  };
  const handleChange = async () => {
    try {
      await AssignToCall.request(RegistrationId, Salesman.value);
      setShowModeratorModal(false);
      refresh((pre) => !pre);
    } catch {}
  };
  const getSalesman = async (newInput) => {
    const inputValue = newInput.replace(/\W/g, "");
    setFilterInput(inputValue);
    return new Promise((resolve, reject) => {
      SalesManCall.request().then((res) => {
        const result = res.data.filter((cat) => cat.user_full_name.concat("-", cat.user_email).toLowerCase().includes(filterInput.toLowerCase()));
        const getData = result.map((cat) => ({
          value: cat.user_id,
          label: cat.user_full_name,
          name: cat.user_full_name,
        }));
        resolve(getData);
      });
    });
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="2" lg="2" xl="2" className="d-none d-lg-inline mb-3"></Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: null, label: "All Requests" },
                { value: "1", label: "Assigned" },
                { value: "2", label: "UnAssigned" },
                { value: "3", label: "Converted" },
                { value: "4", label: "UnConverted" },
              ]}
              handleChange={handleStatus}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchableSelect placeholder="Filter City" value={city} options={citiesOption} handleChange={filterCities} />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !registrationRequests[0] ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={registrationRequests}
                columnNotShow={["id", "request_handled_by", "deleted_at", "status"]}
                dateColumn={["created_at", "updated_at"]}
                sortColumn={["created_at", "updated_at", "shop_name", "first_name", "city"]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                collapsableColumns={["inquiry"]}
                assignTo={assignTo}
                status={{
                  column: "status",
                  getClassName: (value) => {
                    if (value["status"] === "Assigned") {
                      return "text-primary";
                    } else if (value["status"] === "Converted") {
                      return "text-success";
                    } else if (value["status"] === "UnAssigned") {
                      return "text-warning";
                    } else {
                      return "text-danger";
                    }
                  },
                  format: (value) => {
                    if (value["status"] === "Assigned") {
                      return "Assigned";
                    } else if (value["status"] === "Converted") {
                      return "Converted";
                    } else if (value["status"] === "UnAssigned") {
                      return "UnAssigned";
                    } else {
                      return "UnConverted";
                    }
                  },
                }}
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          <PopUpModal size="sm" title="Assign To" show={showModeartorModal} onHide={() => setShowModeratorModal(false)}>
            <label className="mb-1">Assignee</label>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {" "}
              <ReactSelect
                placeholder="Filter Salesman"
                value={Salesman}
                searchable={true}
                options={getSalesman}
                handleChange={(e) => {
                  setSalesman({
                    value: e.value,
                    label: e.label,
                  });
                }}
              />
              <Button onClick={handleChange} className="mt-3 w-50">
                Update
              </Button>
            </div>
          </PopUpModal>
        </>
      </Container>
    </div>
  );
};

export default SellerRegistrationRequests;
