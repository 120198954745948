import React, { useEffect, useState } from "react";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import { Button, Col, Container, Row } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import NoResult from "../../Components/NoResult";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import customloader from "../../assets/images/RollingLoader.gif";
import SearchInput from "../../Components/SearchInput";
import ReactSelect from "../../Components/ReactSelect";
import PopUpModal from "../../Components/Modal";
import { toast } from "react-toastify";

const DeliveryCities = () => {
  const fetchAllDeliveryCitiesCall = useApi(api.fetchAllDeliveryCities, { hasCatchError: true });
  const provincecall = useApi(api.getProvinces);
  const changeDeliveryCityStatus = useApi(postapi.changeDeliveryCityStatus, { hasCatchError: true });

  const [deliveryCities, setDeliveryCities] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ province_name: "ASC" });
  const [search, setSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState({ value: undefined, label: "All Statuses" });
  const [province, setProvince] = useState(null);
  const [activateDeactivateModalShow, setActivateDeactivateModalShow] = useState(false);
  const [deliveryCityData, setDeliveryCityData] = useState({});
  const [error, setError] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isGlobalActive, setIsGlobalActive] = useState(undefined);

  const resetFilterHandle = () => {
    setSizeOfPages(10);
    setCurrentPage(1);
    setOrderBy({ province_name: "ASC" });
    setSearch("");
    setProvince(null);
    setStatusSearch({ value: undefined, label: "All Statuses" });
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchDeliveryCities = async () => {
    try {
      const res = await fetchAllDeliveryCitiesCall.request({
        size: sizeOfPages,
        page: currentPage,
        orderBy: orderBy,
        search: search,
        status: statusSearch.value,
        province: province?.value,
      });
      const data = res?.data?.data;
      setIsLoading(false);
      if (data) {
        setDeliveryCities(data?.cities);
        setIsGlobalActive(data?.isAnyCityActiveForDelivery);
        setCount(data?.pagination?.no_of_items);
        if (data?.pagination?.no_of_pages === 0) {
          setNoOfPages(0);
        } else {
          setNoOfPages(data?.pagination?.no_of_pages);
          if (currentPage > data?.pagination?.no_of_pages) {
            setCurrentPage(data?.pagination?.no_of_pages);
          }
        }
      } else {
        setDeliveryCities([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDeliveryCities();
  }, [currentPage, refresh, sizeOfPages, toggle, orderBy, search, statusSearch, province]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const searchStatus = (e) => {
    if (e.value) {
      setStatusSearch({ value: e.value, label: e.label });
    } else {
      setStatusSearch({ value: undefined, label: "All Statuses" });
    }
    setCurrentPage(1);
  };
  const searchHandle = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
    setCurrentPage(1);
  };
  const getProvince = async () => {
    return new Promise((resolve, reject) => {
      provincecall
        .request()
        .then((res) => {
          const getData = res.data.map((cat) => ({
            value: cat.province_id,
            label: cat.province_name,
          }));
          getData.splice(0, 0, { value: null, label: "All Provinces" });
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const filterProvince = (e) => {
    getProvince();
    if (e.label === "none") {
      setProvince({});
    } else {
      setProvince({ value: e.value, label: e.label });
    }
  };
  const edit = (data) => {
    setActivateDeactivateModalShow(true);
    setDeliveryCityData(data);
  };
  const close = () => {
    setActivateDeactivateModalShow(false);
    setDeliveryCityData({});
    setError(null);
    setIsClicked(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsClicked(true);
      const response = await changeDeliveryCityStatus.request({ id: deliveryCityData["city_id"], active: deliveryCityData["is_active_for_delivery"] === 0 ? true : false });
      if (response.status === 200) {
        toast.success(`${response?.data?.data?.message}`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        close();
        refresh((prev) => !prev);
      }
    } catch (error) {
      setError(error.response.data.error.message);
      refresh((prev) => !prev);
    }
  };

  const globalActiveInactiveHandle = async (e) => {
    try {
      e.preventDefault();
      const action = !isGlobalActive;
      setIsGlobalActive(undefined);
      const response = await changeDeliveryCityStatus.request({ id: "all", active: action });
      if (response.status === 200) {
        toast.success(`${response?.data?.data?.message}`, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        refresh((prev) => !prev);
      }
    } catch (err) {
      setError(error.response.data.error.message);
      refresh((prev) => !prev);
    }
  };

  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <SearchInput value={search} searchlogo handleChange={searchHandle} />
          </Col>
          <Col xs lg="2" className="d-none   d-lg-inline">
            <ReactSelect placeholder="Filter Province" value={province} searchable={false} options={getProvince} handleChange={filterProvince} />
          </Col>
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <SearchableSelect
              placeholder="Filter Status"
              searchable={false}
              value={statusSearch}
              options={[
                { value: undefined, label: "All Statuses" },
                { value: "true", label: "Active" },
                { value: "false", label: "InActive" },
              ]}
              handleChange={searchStatus}
            />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
            <Button className="mt-3 ms-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={globalActiveInactiveHandle} disabled={isGlobalActive === undefined}>
              <span className="d-none d-lg-inline">{isGlobalActive === undefined ? "Loading..." : isGlobalActive ? "Inactivate all cities" : "Activate all cities"}</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !deliveryCities ? (
            <NoResult />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={deliveryCities}
                columnNotShow={["city_id", "province_id", "is_deleted", "is_active_for_delivery", "created_at", "updated_at", "short_code"]}
                sortColumn={["province_name", "city_name"]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                status={{
                  column: ["is_active_for_delivery"],
                  getClassName: (value) => (value["is_active_for_delivery"] === 0 ? "text-danger" : "text-success"),
                  format: (value) => (value["is_active_for_delivery"] === 0 ? "Inactive" : "Active"),
                }}
                ActiveDeactive={{
                  label: (data) => {
                    if (data["is_active_for_delivery"] === 1) {
                      return "Deactivate";
                    } else {
                      return "Activate";
                    }
                  },
                  onClick: (data) => edit(data),
                  variant: (data) => {
                    if (data["is_active_for_delivery"] === 1) {
                      return "outline-danger";
                    } else {
                      return "outline-primary";
                    }
                  },
                  style: { padding: "8px", fontSize: "12px" },
                  column: "is_active_for_delivery",
                }}
                // edit={edit}
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
          {/* <PopUpModal title="Update Price" show={editConsumableItemsModalShow} onHide={() => setEditConsumableItemsModalShow(false)}>
            <EditConsumable toggle={refresh} close={closeModalEdit} data={consumableItemData} />
          </PopUpModal> */}
          <PopUpModal title={`${deliveryCityData["is_active_for_delivery"] === 1 ? "Deactivate" : "Activate"} Confirmation`} show={activateDeactivateModalShow} onHide={close} closeButton={false}>
            <>
              {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

              <div>
                <span className="d-flex justify-content-center pb-4">
                  Are you sure you want to {deliveryCityData["is_active_for_delivery"] === 1 ? "Deactivate" : "Activate"} delivery in {deliveryCityData["city_name"]}?
                </span>
              </div>
              <div className="d-flex gap-3">
                <span
                  className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
                  style={{
                    cursor: "pointer",
                    border: "2px solid rgba(151, 156, 162, 1)",
                    borderRadius: "6px",
                  }}
                  onClick={() => close()}
                >
                  Cancel
                </span>
                <Button className="w-100" onClick={handleSubmit} disabled={isClicked}>
                  {deliveryCityData["is_active_for_delivery"] === 1 ? "Deactivate" : "Activate"}
                </Button>
              </div>
            </>
          </PopUpModal>
        </>
      </Container>
    </div>
  );
};

export default DeliveryCities;
