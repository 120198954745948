import React, { useEffect, useLayoutEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Cookies from "./Features/Cookies";
import { currentUser, authActions } from "./Features/authSlice";
import { useSelector, useDispatch } from "react-redux";

//Components
import DashboardLayout from "./Layout/DashboardLayout";
import Dashboard from "./Pages/Dashboard";
import Loading from "./Pages/Loading";
import Login from "./Pages/Login";
import Shopkeepers from "./Pages/shopkeeper/ShopKeepers";
import Users from "./Pages/Users/Users";
import PointSystem from "./Pages/PointSystem/PointSystem";
import PlansPage from "./Pages/Plans/PlansPage";
import AddPlans from "./Pages/Plans/AddPlans";
import SubscriptionsPage from "./Pages/subscriptions/SubscriptionsPage";
import RequestPage from "./Pages/Request/RequestPage";
import DataPage from "./Pages/data/DataPage";
import PaymentPage from "./Pages/payment/payment";
import BanksPage from "./Pages/BanksPage";
import DataImport from "./Pages/DataImport";
import ForcedRedirectionsPage from "./Pages/ForcedRedirection/ForcedRedirections";
import RedirectionsTable from "./Pages/ForcedRedirection/RedirectionsTable";
import ForcedRedirections from "./Pages/ForcedRedirection/ForcedRedirections";
import SellerRegistrationRequests from "./Pages/SellerRegistrationRequests/SellerRegistrationRequests";
import Notifications from "./Pages/Notifications/Notifications";
import NotificationsTable from "./Pages/Notifications/NotificationsTable";
import AdminNotifications from "./Pages/AdminNotifications";

import AddTheme from "./Pages/Themes/AddTheme";
import Themes from "./Pages/Themes/Themes";
import UsersDeletionRequests from "./Pages/UsersDeletionRequests/UsersDeletionRequests";
import Modules from "./Pages/Modules/Modules";
import PlanRequests from "./Pages/PlanRequests/PlanRequests";
import ConsumableItems from "./Pages/Pricing/ConsumableItems";
import AddonsRequests from "./Pages/AddonsRequest/AddonsRequests";
import ModulePricing from "./Pages/Pricing/ModulePricing";
import io from "socket.io-client";
import { notificationActions } from "./Features/notificationSlice";
import { toast } from "react-toastify";
import unreadNotificationIcon from "./assets/svg/unreadNotificationIcon.svg";
import ThemesPricing from "./Pages/Pricing/ThemesPricing";
import DeliveryCharges from "./Pages/Pricing/DeliveryCharges";
import DeliveryForYouOrders from "./Pages/DeliverForYouOrders/DeliveryForYouOrders";
import DeliveryForYouOrdersStats from "./Pages/DeliverForYouOrders/DeliveryForYouOrdersStats";
import DeliveryCities from "./Pages/MarketplaceOptions/DeliveryCities";
import ShopFeatures from "./Pages/MarketplaceOptions/ShopFeatures";
import FeaturedListingsBookingRequests from "./Pages/FeaturedListingsBookingRequests/FeaturedListingsBookingRequests";
import DeliveryForYouVouchers from "./Pages/DeliverForYouOrders/DeliveryForYouVouchers";
import HomePageConfigurations from "./Pages/MarketplaceOptions/HomePageConfigurations";
const RedirectComponent = () => {
  const { user } = useSelector((state) => state.auth);
  const token = process.env.REACT_APP_ENV === "production" ? Cookies.get("ad_access") : process.env.REACT_APP_ENV === "uat" ? Cookies.get("aaccess") : Cookies.get("ad_token");
  const dispatch = useDispatch();
  const refreshToken = localStorage.getItem("refreshToken");
  const socketRef = React.useRef();
  useLayoutEffect(() => {
    if (token) {
      dispatch(currentUser(refreshToken));
    } else {
      dispatch(authActions.logout());
    }
  }, [dispatch, refreshToken, token]);

  const permissions = user?.permissionObject;
  const userType = user?.user_type;

  useEffect(() => {
    const CustomToast = ({ notificationData }) => (
      <div className="d-flex justify-content-around">
        <img src={unreadNotificationIcon} style={{ marginRight: "5px" }} />
        {notificationData.body}
      </div>
    );
    const notificationCountCallback = (notificationData) => {
      dispatch(notificationActions.notificationCount(notificationData));
    };
    const subscriptionsCallback = (notificationData) => {
      toast(<CustomToast notificationData={notificationData} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        pauseOnHover: false,
        closeButton: true,
        className: "toastMsg",
      });
    };
    const NotificationCallback = (notificationData) => {
      toast(<CustomToast notificationData={notificationData} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        pauseOnHover: false,
        closeButton: true,
        className: "toastMsg",
      });
    };
    if (token && token !== "undefined") {
      if (!socketRef.current) {
        socketRef.current = io(process.env.REACT_APP_API_URL, {
          auth: {
            token,
          },
        });
      }
      const socket = socketRef.current;
      socket.on("notificationCount", notificationCountCallback);
      socket.on("subscriptions", subscriptionsCallback);
      socket.on("addonsRequest", NotificationCallback);
      socket.on("deliveryOrder", NotificationCallback);
      return () => {
        socket.off("notificationCount", notificationCountCallback);
        socket.off("subscriptions", subscriptionsCallback);
        socket.off("addonsRequest", NotificationCallback);
        socket.off("deliveryOrder", NotificationCallback);
      };
    } else {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    }
  }, [token]);

  return (
    <Routes>
      {user && token ? (
        <Route
          path="*"
          element={
            <Routes>
              <Route path="/" element={<DashboardLayout />}>
                <Route path="/" element={<Dashboard />} />
                {(userType === "super-admin" || Number(permissions?.Shopkeeper) === 1) && <Route path="Shopkeepers" element={<Shopkeepers />} />}
                {(userType === "super-admin" || Number(permissions?.User) === 1) && (
                  <Route path="user" element={<Outlet />}>
                    <Route path="" element={<Users />} />
                  </Route>
                )}

                {(userType === "super-admin" || Number(permissions?.DataImport) === 1) && (
                  <Route path="ImportRequests" element={<Outlet />}>
                    <Route path="" element={<DataImport />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Payment) === 1) && (
                  <Route path="Payment" element={<Outlet />}>
                    <Route path="" element={<PaymentPage />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Subscription) === 1) && (
                  <Route path="subscription" element={<Outlet />}>
                    <Route path="plans" element={<PlansPage />} />
                    <Route path="addplans" element={<AddPlans />} />
                    <Route path="request-details" element={<RequestPage />} />
                    <Route path="modules" element={<Modules />} />

                    <Route path="plan-requests" element={<PlanRequests />} />

                    <Route path="" element={<SubscriptionsPage />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Pricing) === 1) && (
                  <Route path="pricing" element={<Outlet />}>
                    <Route path="consumable-items" index element={<ConsumableItems />} />
                    <Route path="modules-pricing" element={<ModulePricing />} />
                    <Route path="themes-pricing" element={<ThemesPricing />} />
                    <Route path="delivery-charges" element={<DeliveryCharges />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.AddonsRequests) === 1) && (
                  <Route path="/addons-requests" element={<Outlet />}>
                    <Route path="" element={<AddonsRequests />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.PointSystem) === 1) && (
                  <Route path="Pointsystem" element={<Outlet />}>
                    <Route path="" element={<PointSystem />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Bank) === 1) && (
                  <Route path="bank" element={<Outlet />}>
                    <Route path="" element={<BanksPage />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.ForcedRedirections) === 1) && (
                  <Route path="forced-redirections" element={<Outlet />}>
                    <Route path="" element={<RedirectionsTable />} />
                    <Route path="add-redirection" element={<ForcedRedirections />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.RegistrationRequests) === 1) && (
                  <Route path="seller-registration" element={<Outlet />}>
                    <Route path="" element={<SellerRegistrationRequests />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.DeliveryOrder) === 1) && (
                  <Route path="delivery-for-you" element={<Outlet />}>
                    <Route path="" element={<Navigate to="orders" />} />
                    <Route path="orders" element={<DeliveryForYouOrders />} />
                    <Route path="stats" element={<DeliveryForYouOrdersStats />} />
                    <Route path="vouchers" element={<DeliveryForYouVouchers />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Themes) === 1) && (
                  <Route path="theme" element={<Outlet />}>
                    <Route path="add" element={<AddTheme />} />
                    <Route path="" element={<Themes />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.MarketplaceOptions) === 1) && (
                  <Route path="marketplace-options" element={<Outlet />}>
                    <Route path="shop-features" index element={<ShopFeatures />} />
                    <Route path="delivery-cities" element={<DeliveryCities />} />
                    <Route path="configs" element={<HomePageConfigurations />} />
                  </Route>
                )}
                {userType === "super-admin" && (
                  <Route path="data" element={<Outlet />}>
                    <Route path="" element={<DataPage />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.Notifications) === 1) && (
                  <Route path="notifications" element={<Outlet />}>
                    <Route path="" element={<NotificationsTable />} />
                    <Route path="send" element={<Notifications />} />
                  </Route>
                )}
                <Route path="admin-notifications" element={<AdminNotifications />} />
                {/* {userType === "super-admin" && (
                  <Route path="theme" element={<Outlet />}>
                    <Route path="add" element={<AddTheme />} />
                    <Route path="" element={<Themes />} />
                  </Route>
                )}{" "} */}
                {(userType === "super-admin" || Number(permissions?.UserDeletionRequests) === 1) && (
                  <Route path="users-deletion-requests" element={<Outlet />}>
                    <Route path="" element={<UsersDeletionRequests />} />
                  </Route>
                )}
                {(userType === "super-admin" || Number(permissions?.FeaturedListingsBookings) === 1) && (
                  <Route path="featured-listings-bookings" element={<Outlet />}>
                    <Route path="" element={<FeaturedListingsBookingRequests />} />
                  </Route>
                )}
              </Route>
              <Route exact path="/login" element={<Navigate to="/" />} />
              <Route exact path="*" element={<Navigate to="/" />} />
            </Routes>
          }
        />
      ) : (
        <Route
          path="*"
          element={
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route path="/set-password/:token" element={<Loading />} />
              <Route exact path="*" element={token ? <Outlet /> : <Navigate to="/login" />} />
            </Routes>
          }
        />
      )}
    </Routes>
  );
};

export default RedirectComponent;
