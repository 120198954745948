import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import axios from "axios";

const RenderPhotos = ({ source = [], onDelete }) => {
    return (
        <div style={{ padding: "0 10%" }}>
            {source.map((file, index) => (
                <div key={index} className="d-flex align-items-center mb-3">
                    <img
                        src={file.preview}
                        alt="Preview"
                        style={{ maxWidth: "100px", maxHeight: "100px", marginRight: "10px" }}
                    />
                    <Button
                        variant="danger"
                        onClick={() => onDelete(file)}
                    >
                        Delete
                    </Button>
                </div>
            ))}
        </div>
    );
};

const ImageUploader = forwardRef(
    (
        { selectedFiles = [], setSelectedFiles, numberOfImages = 10, isInvalid, disabled = false },
        ref
    ) => {
        const [fileRejectionsState, setFileRejectionsState] = useState([]);

        const {
            acceptedFiles,
            fileRejections,
            getRootProps,
            getInputProps,
            open,
        } = useDropzone({
            onDrop: (acceptedFiles, fileRejections) => {
                setFileRejectionsState(fileRejections); // Store rejections in state
                const filesArray = acceptedFiles.map((file) => ({
                    preview: URL.createObjectURL(file),
                    data: file,
                }));
                setSelectedFiles((prev) => [...prev, ...filesArray]);
            },
            accept: {
                "image/png": [".png"],
                "image/jpeg": [".jpg", ".jpeg"],
                "image/webp": [".webp"],
            },
            maxFiles: numberOfImages - selectedFiles.length,
        });

        useEffect(() => {
            if (fileRejectionsState.length > 0) {
                const errors = fileRejectionsState.map((fileRejection) => fileRejection.errors[0]);
                toast.error(errors[0]?.message || "File upload error", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        }, [fileRejectionsState]);

        const onDelete = (photo) => {
            setSelectedFiles((prev) => prev.filter((img) => img !== photo));
        };

        useImperativeHandle(ref, () => ({
            uploadingImages: () => {
                return new Promise((resolve, reject) => {
                    if (selectedFiles.length > 0) {
                        const formData = new FormData();
                        selectedFiles.forEach((file) => {
                            formData.append("files", file.data, file.data.name);
                        });

                        axios
                            .post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images`, formData, {
                                headers: {
                                    Accept: "application/json",
                                    "content-type": "multipart/form-data",
                                },
                            })
                            .then(({ data }) => {
                                resolve(data.files);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    } else {
                        resolve([]);
                    }
                });
            },
        }));

        return (
            <>
                <Form.Group
                    className={`d-grid justify-content-center pt-0 mt-0`}
                    style={{
                        ...(isInvalid ? { border: "1px solid red", borderRadius: "8px" } : {}),
                        ...(disabled ? { pointerEvents: "none", background: "#e9ecef", opacity: "0.7" } : {}),
                    }}
                >
                    {selectedFiles.length < numberOfImages && (
                        <div className="d-flex justify-content-center align-items-center">
                            <Button onClick={open}>
                                <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faCloudArrowUp} /> Add Images +
                            </Button>
                        </div>
                    )}
                    {/* {selectedFiles.length > 0 && (
                        <RenderPhotos source={selectedFiles} onDelete={onDelete} />
                    )} */}
                </Form.Group>
            </>
        );
    }
);

export default ImageUploader;
