import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";

function AddCharges({ toggle, close, consumableItemData, edit }) {
  // console.log(consumableItemData);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [customForm, setCustomForm] = useState({
    categoryName: "",
    city: {},
    charges: "",
  });
  const [cities, setCities] = useState([]);
  const fetchAllCharges = useApi(api.getCitiess);
  const saveCharges = useApi(postapi.addChargesForCity);
  const updateCharges = useApi(postapi.updateChargesForCity);
  const categories = [
    {
      value: "2",
      label: "Mobile",
    },
    { value: "3", label: "Tablet" },
    { value: "4", label: "TV / Monitor" },

    { value: "1", label: "Laptop" },
    { value: "5", label: "Desktop Computer" },
    { value: "6", label: "Accessories" },
  ];
  const handleChange = (e) => {
    setCustomForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    let obj = {
      city_id: customForm.city.value,
      city_name: customForm.city.label,
      category_id: Number(customForm.categoryName.value),
      category_name: customForm.categoryName.label,
      charges: customForm.charges,
    };
    try {
      if (edit) {
        const { data, error } = await updateCharges.request(obj, consumableItemData?.id);
      } else {
        const { data, error } = await saveCharges.request(obj);
      }
      setValidated(false);
      // console.log(error, data);
      setCustomForm({
        categoryName: "",
        city: {},
        charges: "",
      });
      toggle((prev) => !prev);
      close();
      setError("");
    } catch (err) {
      console.log(error);
      setError(saveCharges.error.data.error);
    }
  };
  const handleSelectChange = (e, name) => {
    setCustomForm((prev) => ({ ...prev, [name]: e }));
  };
  const getAllCities = async () => {
    try {
      let data = await fetchAllCharges.request();
      setCities(data?.data?.map((item) => ({ label: item.city_name, value: item.city_id })));
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllCities();
    if (edit) {
      // console.log(edit);
      setCustomForm({
        categoryName: {
          value: consumableItemData?.category_id,
          label: consumableItemData?.category_name,
        },
        city: {
          value: consumableItemData?.city_id,
          label: consumableItemData?.city_name,
        },
        charges: consumableItemData?.charges,
      });
    } else {
      setCustomForm({
        categoryName: "",
        city: {},
        charges: "",
      });
    }
  }, [edit]);
  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {error && <span className="text-danger p-2 text-center my-2 rounded-1">*{error}</span>}

        <Row className="">
          <Col className="py-3" md={6}>
            <Form.Group>
              <SearchableSelect placeholder="Select" label="categoryName " value={customForm.categoryName} searchable={false} options={categories} handleChange={(e) => handleSelectChange(e, "categoryName")} disabled={edit} />
              <Form.Control value={customForm?.categoryName} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please select Category.</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" md={6}>
            <Form.Group>
              <SearchableSelect placeholder="Select" label="City " value={customForm.city} searchable={false} options={cities} handleChange={(e) => handleSelectChange(e, "city")} disabled={edit} />
              <Form.Control value={customForm?.city} onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please select City.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" lg="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1">
                Charges <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Enter Charges"
                name="charges"
                value={customForm.charges}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="py-3">
          <Col>
            <Button type="submit" className="w-100">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddCharges;
