import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};

export function getVendors(data) {
  return api.post(`/vendors/get`, { ...data }, { withCredentials: true });
}
// shopkeeper
export function ActivateShops(id, data) {
  return api.post(`/shops/activate/${id}`, data, { withCredentials: true });
}
export function DeactivateShops(id, data) {
  return api.post(`/shops/deactivate/${id}`, data, { withCredentials: true });
}
export function EditShop(id, data) {
  return api.patch(`/shops/${id}`, data, { withCredentials: true });
}
export function ChangeModerator(id, salesman) {
  return api.patch(`/shops/moderator/${id}`, { salesman }, { withCredentials: true });
}

// user
export function AddtechUser(data) {
  return api.post(`/user/create-tech-user`, data, { withCredentials: true });
}
export function AddAdminUser(data) {
  return api.post(`/user/create-admin-user`, data, { withCredentials: true });
}
export function DeactivateTechUser(id) {
  return api.post(`/user/tech-bazaar-user/deactivate/${id}`, {}, { withCredentials: true });
}
export function ActivateTechUser(id) {
  return api.post(`/user/tech-bazaar-user/activate/${id}`, {}, { withCredentials: true });
}
export function DeactivateSellerUser(id) {
  return api.post(`/user/seller-user/deactivate/${id}`, {}, { withCredentials: true });
}
export function ActivateSellerUser(id) {
  return api.post(`/user/seller-user/activate/${id}`, {}, { withCredentials: true });
}
export function editAdmin(data) {
  const { id, ...remData } = data;

  return api.patch(`user/admin-user/${id}`, remData, { withCredentials: true });
}
export function editTechUser(id, data) {
  return api.patch(`user/tech-user/${id}`, data, { withCredentials: true });
}
export function changePassword(id, data) {
  return api.patch(`user/change-password/${id}`, { password: data }, { withCredentials: true });
}

// plans
export function AddPlan(data) {
  return api.post(`/subscriptions/plans`, data, { withCredentials: true });
}
export function EditPLan(id, data) {
  return api.patch(`/subscriptions/plans/${id}`, data, {
    withCredentials: true,
  });
}

// request
export function AddRequest(data) {
  return api.post(`/request/create`, data, { withCredentials: true });
}
export function AddNote(id, data) {
  return api.post(`/request/notes/${id}`, { notes: data }, { withCredentials: true });
}
export function AssignMee(id, data) {
  return api.post(`/request/assign/${id}`, { support: data }, { withCredentials: true });
}

// subscription

export function SubmitShiftPlan(id, data) {
  return api.post(`/transactions/lock/${id}`, data, { withCredentials: true });
}
export function TrialExpired(id) {
  return api.patch(`/subscriptions/trial-expire/${id}`, {}, { withCredentials: true });
}
export function ChangePlan(transaction) {
  const id = transaction.shop_id;
  return api.post(`/subscriptions/change-plan/${id}`, { transaction }, { withCredentials: true });
}
export function PayForNextPlan(transaction) {
  const id = transaction.shop_id;
  return api.post(`/transactions/pay/${id}`, { transaction }, { withCredentials: true });
}

//Themes
export function AddTheme(data) {
  return api.post(`/themes/add`, data, { withCredentials: true });
}
export function EditTheme(id, data) {
  return api.put(`/themes/update/` + id, data, { withCredentials: true });
}

export function DeleteTheme(id) {
  return api.delete(`/themes/` + id, { withCredentials: true });
}

export function GetTheme(id) {
  return api.get(`/themes/` + id, { withCredentials: true });
}
// export fuction ActivateTheme(id) {

// }

// uploadImage

export function UPloadImage(data) {
  return api.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/images/transactions`, data, { withCredentials: true });
}

export function PostData(data) {
  return api.post(`/components`, data, { withCredentials: true });
}

export function EditComponent(id, data) {
  return api.patch(`/components/${id}`, data, { withCredentials: true });
}

export function SendNotification(data) {
  return api.post(`/components`, data, { withCredentials: true });
}

//Banks
export function addBanks(data) {
  return api.post(`/banks`, data, { withCredentials: true });
}
export function editBank(id, data) {
  return api.patch(`/banks/${id}`, data, { withCredentials: true });
}

export function saveProgress(id, data) {
  return api.put(`data-import-requests/validate/${id}/save-progress/`, { data: data }, { withCredentials: true });
}

export function disapprovedRequest(id, data) {
  return api.put(`/data-import-requests/disapprove/${id}`, data, {
    withCredentials: true,
  });
}

export function transformFile(id, userId, mapping) {
  return api.post(`data-import-requests/transform/${id}`, { userId: userId, ...(mapping ? { customMapping: mapping } : {}) }, { withCredentials: true });
}

export function finalize(id, userId) {
  return api.put(`data-import-requests/finalize/${id}`, { userId: userId }, { withCredentials: true });
}

export function AssignTo(id, salesman) {
  return api.put(
    `/become-seller/${id}`, //registration request id
    { request_handled_by: salesman },
    { withCredentials: true }
  );
}

export function AddSellerModule(data) {
  return api.post(`/modules/add`, data, { withCredentials: true });
}

export function editSellerModule(id, data) {
  return api.put(`/modules/update/${id}`, data, { withCredentials: true });
}
export function processPlanRequestCall(id, status) {
  return api.put(`/plan-requests/${id}`, status, { withCredentials: true });
}
export function editConsumableItem(name, data) {
  return api.patch(`addons/pricing/consumable-item/${name}`, { per_unit_price: data }, { withCredentials: true });
}
export function addonsRequest(data) {
  return api.post(`addons/request`, { shop_id: data?.shop_id, is_paid: data?.is_paid, requested_items: data?.requested_items }, { withCredentials: true });
}
export function disapproveAddonsRequest(id, status) {
  return api.patch(`addons/request/${id}/status`, status, {
    withCredentials: true,
  });
}
export function approveAddonsRequestWithoutPayment(id) {
  return api.patch(
    `addons/request/${id}/approve`,
    {},
    {
      withCredentials: true,
    }
  );
}
export function approveAddonsRequest(id, data) {
  return api.patch(`/addons/request/${id}/approve`, data, { withCredentials: true });
}
export function addExtensionRequest(data) {
  return api.post(`/addons/extension-request`, data, { withCredentials: true });
}

export function approveExtensionAddonsRequest(id) {
  return api.patch(
    `addons/extension-request/${id}/approve`,
    {},
    {
      withCredentials: true,
    }
  );
}
export function editModulePrice(id, data) {
  return api.put(`modules/update/${id}`, { price: data }, { withCredentials: true });
}

export function editThemePrice(id, data) {
  return api.put(`themes/update/${id}`, { price: data }, { withCredentials: true });
}
export function addChargesForCity(data) {
  return api.post(`delivery-charges/add`, data, { withCredentials: true });
}
export function updateChargesForCity(data, id) {
  return api.put(`delivery-charges/${id}`, data, { withCredentials: true });
}
export function changeOrderStatus(data) {
  const { id, status, reason, postman_name, postman_phone } = data;
  const dataObj = {
    ...(status ? { status } : {}),
    ...(reason ? { reason } : {}),
    ...(postman_name ? { postman_name } : {}),
    ...(postman_phone ? { postman_phone } : {}),
  };
  return api.patch(`d4u-orders/${id}/change-status`, dataObj, {
    withCredentials: true,
  });
}
export function changeDeliveryCityStatus(data) {
  return api.patch(`city/${data?.id}/change-delivery-status`, { active: data.active }, { withCredentials: true });
}
export function Changed4uStatus(data) {
  return api.patch(`shop-options/${data?.id}/change-d4u-status`, { active: data.active }, { withCredentials: true });
}
export function addSpots(data) {
  const { place, total_spots, available_spots, price, spot_weight } = data;
  return api.post(`featured-listings/spots`, { place, total_spots, available_spots, price, spot_weight }, { withCredentials: true });
}

export function updateSpot(data) {
  const { id, ...rest } = data;
  return api.patch(`featured-listings/spots/${data?.id}`, rest, { withCredentials: true });
}
export function deleteSpot(spotId) {
  return api.delete(`featured-listings/spots/${spotId}`, { withCredentials: true });
}
export function assignSpots(bookingId, data) {
  return api.patch(`featured-listings/bookings/${bookingId}/assign-spots`, data, { withCredentials: true });
}
export function payFeaturedListingsBookingRequest(bookingId, data) {
  return api.post(`featured-listings/bookings/${bookingId}/add-trx`, { trxData: data }, { withCredentials: true });
}

export function approveFeaturedListingsBookingRequest(bookingId) {
  return api.patch(`featured-listings/bookings/${bookingId}/confirm`, {}, { withCredentials: true });
}

export function rejectFeaturedListingsBookingRequest(bookingId) {
  return api.patch(`featured-listings/bookings/${bookingId}/reject`, {}, { withCredentials: true });
}
export function AddVoucher(data) {
  return api.post(`d4u-orders/vouchers`, { ...data }, { withCredentials: true });
}
export function UpdateVoucher(id, data) {
  return api.patch(`d4u-orders/vouchers/${id}`, { ...data }, { withCredentials: true });
}
export function deleteVoucher(id) {
  return api.delete(`d4u-orders/vouchers/${id}`, { withCredentials: true });
}

// Marketplace options
// export default function UploadBanner(data) {
//   console.log(data);

//   return api.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/marketplace/home-page-banners`, data, {
//     withCredentials: true,
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   })
// }
