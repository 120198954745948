import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, Modal, ModalBody, ModalHeader, Row, Tab, Tabs } from "react-bootstrap";
import styles from './config.modules.css'

import axios from "axios";
// import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import { useSelector } from "react-redux";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUploader from "./ImageUploader";

const HomePageConfigurations = () => {
  const [show, setShow] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedBannedID, setSelectedBannedID] = useState(null);
  const [link, setLink] = useState([])
  const { user } = useSelector((state) => state.auth);
  const [banner, setBanner] = useState({})
  const [mode, setMode] = useState('add')
  const [page, setPage] = useState('home')

  useEffect(() => {
    getBannerByPage()
  }, [page, show])

  useEffect(() => {
    // if ( selectedFiles.length > 0) {
    UploadImage();

    // Only re-run when selectedFiles changes
  }, [selectedFiles]);

  useEffect(() => { fetchBannersByLocation() }, [show])


  const urlRegex = /\b(?:https?:\/\/(?:www\.)?(?:localhost:3000|staging\.techbazaar\.pk|uat\.techbazaar\.pk|techbazaar\.pk))\b/;



  function validateURL(url) {
    return urlRegex.test(url);
  }


  const handleClick = (e, mode) => {
    e.preventDefault();
    setLink([])
    setSelectedBannedID([])
    // console.log(e.currentTarget.closest('#main_banner').getAttribute('page'));



    setSelectedBannedID(e.target.closest('[id]').id);
    setPage(e.currentTarget.closest('[id]').getAttribute('page'))

    setMode(mode)

    setShow(true);

  };

  const getBannerByPage = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/marketplace/banners/${page}`, { withCredentials: true }
      ).then((res) => {
        setBanner(res.data.success.data.banners)

      }).catch((err) => {
        console.log(err);
      })
    } catch
    (error) {
      console.log(error);
    }

  }

  const UploadImage = async () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      throw new Error("Please select an image to upload.");
    }

    const formData = new FormData();

    // Validate and append files to formData
    selectedFiles.forEach((file) => {
      if (!selectedBannedID || !file?.data) {
        throw new Error("Invalid file data or selectedBannedID is undefined.");
      }
      formData.append(selectedBannedID, file.data);
    });

    // Append static links payload
    const linksPayload = { main_banner: {}, category_A: {}, category_B: {}, main_sub_banner: {}, discover_more: {}, carousal_1: {}, carousal_2: {}, plp_Carousal: {} };
    formData.append("links", JSON.stringify(linksPayload));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_IMAGE_CDN}/marketplace/home-page-banners`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Extract new links from the API response
      const newLinks = response.data.flatMap((data) =>
        data.images.map((image) => ({ thumbnail: image.key, link: "" }))
      );

      setLink([...link, ...newLinks.flat()]);



    } catch (error) {
      console.error("Error uploading image:", error?.response?.data || error.message);
    }
  };



  const handleLinkChange = (index, newLink) => {
    if (validateURL(newLink)) {
      const updatedLinks = [...link];
      updatedLinks[index].link = newLink;
      setLink(updatedLinks);
    } else {

      console.error("Invalid URL:", newLink);
    }
  };

  const fetchBannersByLocation = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/marketplace/banners/${page}/${selectedBannedID}`, { withCredentials: true }).then((res) => {
        setLink(res?.data?.success?.data?.map((data, index) => {
          return {
            thumbnail: data.img,
            link: data.link

          }
        }))


      }).catch((err) => {
        console.log(err);
      })
    }

    catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {

  }, [link])




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = link.map((item) => ({
        style: ["main_banner", "carousal_1", "carousal_2", 'plp_Carousal'].includes(selectedBannedID)
          ? "carousel"
          : "single",
        page: page,
        location: selectedBannedID,
        img: item.thumbnail,
        link: item.link || "",
        sequence: 0,
        expires_on: null,
        uploaded_by: user?.user_id,
        created_at: Date.UTC(),
        updated_at: null,
        is_deleted: false



      }));

      // console.log(payload, 'payload');



      if (mode === 'add') {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/marketplace/banners`,
          payload,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          setShow(false)
          setSelectedFiles([])
          setLink([])
          getBannerByPage()
        }
        );

      } else if (mode === 'edit') {
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/marketplace/banners/${page}/${selectedBannedID}`,
          payload,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }).then(res => {
            setShow(false)
          })
      }



    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const onDelete = async (itemToDelete) => {
    console.log(itemToDelete.thumbnail, 'itemToDelete');

    setLink((prevLinks) => prevLinks.filter(item => item !== itemToDelete));
    await axios.post(`${process.env.REACT_APP_URL_IMAGE_CDN}/delete`, {
      files: [
        itemToDelete.thumbnail
      ]
    }, { withCredentials: true },
    );
  };




  return (
    <>
      <Container fluid>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3">
          <Tab eventKey="home" title="Home">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" onClick={() => setPage('home')}>
                <Accordion.Header>Top Banner</Accordion.Header>
                <Accordion.Body className="">
                  <div className="grid_container">
                    {banner?.main_banner?.length >= 1 ? (
                      <>
                        <div id="main_banner" className="btn_hover" page='home'>
                          <img
                            src={`${process.env.REACT_APP_URL_S3}/${banner?.main_banner[0]?.img}`}
                            alt="main banner"
                            className="banner-img"
                          />
                          <div className="d-flex align-center button-container">
                            <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                          </div>
                          <div className="position-absolute  bottom-0 ps-2 text-muted fw-bold fs-12">
                            700 x 300

                          </div>
                        </div>
                      </>
                    ) : (
                      <div className=" skeleton btn_hover" id="main_banner" page='home' >
                        <div className="d-flex align-center button-container">
                          <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          700 x 300

                        </div>
                      </div>
                    )}




                    {banner?.category_A?.length >= 1 ? <>
                      <div id="category_A" className="btn_hover" page='home'>
                        <img
                          src={`${process.env.REACT_APP_URL_S3}/${banner?.category_A[0]?.img}`}
                          alt="main banner"
                          className="banner-img"
                        />
                        <div className="d-flex align-center button-container" page='home'>
                          <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          250 x 150

                        </div>
                      </div>

                    </> : <>
                      <div className="  skeleton btn_hover " id="category_A" page='home'>
                        <div className="d-flex align-center button-container ">
                          <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          250 x 150

                        </div>
                      </div></>}

                    {banner?.category_B?.length >= 1 ? <>
                      <div className="btn_hover" id="category_B" page='home'>
                        <img
                          src={`${process.env.REACT_APP_URL_S3}/${banner?.category_B[0]?.img}`}
                          alt="main banner"
                          className="banner-img"
                        />
                        <div className="d-flex align-center button-container">
                          <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          250 x 150

                        </div>
                      </div>

                    </> : <>
                      <div className="skeleton btn_hover " id="category_B" page='home'>
                        <div className="d-flex align-center button-container ">
                          <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          250 x 150

                        </div>
                      </div></>}





                    {banner?.main_sub_banner?.length >= 1 ? <>
                      <div className="btn_hover" id="main_sub_banner" page='home'>
                        <img
                          src={`${process.env.REACT_APP_URL_S3}/${banner?.main_sub_banner[0]?.img}`}
                          alt="main banner"
                          className="banner-img"
                        />
                        <div className="d-flex align-center button-container">
                          <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          450 x 200

                        </div>
                      </div>
                    </> : <>
                      <div className="skeleton btn_hover " id="main_sub_banner" page='home'>
                        <div className="d-flex align-center button-container ">
                          <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          450 x 200

                        </div>
                      </div></>}

                    {banner?.discover_more?.length >= 1 ? <>
                      <div className="btn_hover" id="discover_more" page='home'>
                        <img
                          src={`${process.env.REACT_APP_URL_S3}/${banner?.discover_more[0]?.img}`}
                          alt="main banner"
                          className="banner-img"
                        />
                        <div className="d-flex align-center button-container">
                          <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12 ">
                          450 x 100

                        </div>
                      </div>
                    </> : <>
                      <div className=" skeleton btn_hover " id="discover_more" page='home'>
                        <div className="d-flex align-center button-container ">
                          <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                        </div>
                        <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                          450 x 100

                        </div>
                      </div>
                    </>

                    }




                  </div>

                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="mt-3" onClick={() => setPage('home')}>
                <Accordion.Header>Carousal 1</Accordion.Header>
                <Accordion.Body>
                  {banner?.carousal_1?.length ? <>
                    <div id="carousal_1" className="w-100 btn_hover" page='home' style={{ height: "200px" }}>
                      <img
                        src={`${process.env.REACT_APP_URL_S3}/${banner?.carousal_1[0]?.img}`}
                        alt="main banner"
                        className="banner-img"
                      />
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>

                  </> : <>
                    <div id="carousal_1" className="w-100 skeleton btn_hover" page='home' style={{ height: "200px" }}>
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>
                  </>}



                </Accordion.Body>

              </Accordion.Item>


              <Accordion.Item eventKey="2" className="mt-3" onClick={() => setPage('home')}>
                <Accordion.Header>Carousal 2</Accordion.Header>
                <Accordion.Body>
                  {banner?.carousal_2?.length ? <>
                    <div id="carousal_2" className="w-100 btn_hover" page='home' style={{ height: "200px" }}>
                      <img
                        src={`${process.env.REACT_APP_URL_S3}/${banner?.carousal_2[0]?.img}`}
                        alt="main banner"
                        className="banner-img"
                      />
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>

                  </> : <>
                    <div id="carousal_2" className="w-100 skeleton btn_hover" page='home' style={{ height: "200px" }}>
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>
                  </>}



                </Accordion.Body>

              </Accordion.Item>




            </Accordion>
          </Tab>
          <Tab eventKey="plp" title="PLP">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mt-3" onClick={() => setPage('plp')}>
                <Accordion.Header>PLP Carousal</Accordion.Header>
                <Accordion.Body>
                  {banner?.plp_Carousal?.length ? <>
                    <div id="plp_Carousal" className="w-100 btn_hover" page='plp' style={{ height: "200px" }}>
                      <img
                        src={`${process.env.REACT_APP_URL_S3}/${banner?.plp_Carousal[0]?.img}`}
                        alt="main banner"
                        className="banner-img"
                      />
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'edit')} variant="success">Edit</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>

                  </> : <>
                    <div id="plp_Carousal" className="w-100 skeleton btn_hover" page='plp' style={{ height: "200px" }}>
                      <div className="button-container">
                        <Button onClick={(e) => handleClick(e, 'add')} variant="outline-primary">Add +</Button>
                      </div>
                      <div className="position-absolute bottom-0 ps-2 text-muted fw-bold fs-12">
                        1500x200

                      </div>
                    </div>
                  </>}



                </Accordion.Body>

              </Accordion.Item>


            </Accordion>

          </Tab>

          <Tab className='text-muted' eventKey="category" title="Category" disabled></Tab>
          <Tab className='text-muted' eventKey="shop" title="Shop" disabled></Tab>
          <Tab className='text-muted' eventKey="pdp" title="PDP" disabled></Tab>
          <Tab className='text-muted' eventKey="other" title="Other" disabled></Tab>






        </Tabs>
      </Container>

      <Modal centered size="lg" show={show} onHide={() => { setShow(false); setSelectedFiles([]); setLink([]); getBannerByPage() }}>
        <Modal.Header closeButton> <Modal.Title>Upload Image</Modal.Title></Modal.Header>
        <Modal.Body>
          <ImageUploader selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} numberOfImages={selectedBannedID === 'main_banner' || selectedBannedID === 'carousal_2' || selectedBannedID === 'carousal_1' ? 5 : 1} />
          <Form onSubmit={handleSubmit} >



            {link?.length > 0 &&
              link.map((item, index) => (
                <div key={index} className="d-flex align-items-center my-2">
                  <div style={{ width: "100px", height: "40px" }}>
                    <img
                      src={`${process.env.REACT_APP_URL_S3}/${item.thumbnail}`}
                      alt="thumbnail"
                      style={{ objectFit: "contain", width: "100%", height: "100%" }}
                    />
                  </div>
                  <Form.Control
                    className="ms-1"
                    placeholder="Link"
                    value={item.link}
                    onChange={(e) => handleLinkChange(index, e.target.value)}
                  />
                  <FontAwesomeIcon icon={faClose} alt="close" className="ms-1" onClick={() => onDelete(item)} />
                </div>
              ))}

            <div className="d-flex justify-content-center my-2">
              <Button type="submit">Save</Button>
            </div>
          </Form>


        </Modal.Body >
      </Modal >
    </>
  );
};

export default HomePageConfigurations;


