import React, { useEffect, useState } from "react";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import { Button, Col, Container, Row } from "react-bootstrap";
import moment from "moment/moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import PopUpModal from "../../Components/Modal";
import AddSpotsModal from "../../Components/FeaturedListingsBookings/AddSpotsModal";
import SpotsModal from "../../Components/FeaturedListingsBookings/SpotsModal";
import DisapprovedModal from "../../Components/Addons/DisapproveRequestModal";
import ProcessBookingRequestModal from "../../Components/FeaturedListingsBookings/ProcessBookingRequestModal";
import FeaturedBookingPaymentModal from "../../Components/FeaturedListingsBookings/FeaturedBookingPaymentModal";
import { dateTime, dateTimeForInvoice } from "../../Utilities/dateTime";

const FeaturedListingsBookingRequests = () => {
  const [featuredBookingRequests, setFeaturedBookingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [refresh, toggle] = useState(false);
  const [orderBy, setOrderBy] = useState({ BookingDatetime: "DESC" });
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(100, "years")._d);
  const [toDate, setToDate] = useState(moment().add(100, "years")._d);
  const [error, setError] = useState("");
  const [caretData, setCaretData] = useState();
  const [addSpotsModalShow, setAddSpotsModalShow] = useState(false);
  const [showSpotsModal, setShowSpotsModal] = useState(false);
  const [requestId, setRequestId] = useState();
  const [disapprovedModalShow, setDisapprovedModalShow] = useState(false);
  const [processRequestModalShow, setProcessRequestModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);

  const fetchAllFeaturedBookingRequestsCall = useApi(api.fetchAllFeaturedBookingRequests, { hasCatchError: true });
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
      setCurrentPage(1);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setStatus("");
    setFromDate(moment().subtract(100, "years")._d);
    setToDate(moment().add(100, "years")._d);
    setCurrentPage(1);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
    setCurrentPage(1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const fetchFeaturedBookingsRequests = async () => {
    try {
      const res = await fetchAllFeaturedBookingRequestsCall.request({
        size: sizeOfPages,
        page: currentPage,
        search: searchInput,
        orderBy: orderBy,
        date: { from: fromDate, to: toDate },
        status: status.value,
      });
      const data = res?.data?.success;
      setIsLoading(false);
      if (data) {
        setFeaturedBookingRequests(data.data);
        setCount(data?.pagination?.totalCount);
        if (data?.pagination?.totalPages === 0) {
          setNoOfPages(0);
        } else {
          setNoOfPages(data?.pagination?.totalPages);
          if (currentPage > data?.pagination?.totalPages) {
            setCurrentPage(data?.pagination?.totalPages);
          }
        }
      } else {
        setFeaturedBookingRequests([]);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
      setFeaturedBookingRequests([]);
    }
  };
  useEffect(() => {
    fetchFeaturedBookingsRequests();
  }, [currentPage, sizeOfPages, searchInput, refresh, toDate, fromDate, status, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      toggle((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus("");
    } else {
      setStatus({ value: e.value, label: e.label });
      setCurrentPage(1);
    }
  };

  const closeModalAdd = () => {
    setAddSpotsModalShow(false);
  };

  const caret = async (bookingId) => {
    const data = featuredBookingRequests.filter((item) => item.id === bookingId);
    const listings = data[0].listings.map((item) => ({ title: item.listing_title, status: item.activation_date !== null ? "Active" : "Deactivated" }));
    const bookingObj = {
      ...(data[0].trxData.length > 0
        ? {
            "Transaction Details": (
              <>
                <div>Total Amount : Rs.{data[0].trxData[0].trx_amount}</div>
                <div>Status :&nbsp;{data[0].trxData[0].trx_status}</div>
                <div>Transaction Date :&nbsp;{dateTimeForInvoice(data[0].trxData[0].trx_datetime).toISOString().split("T")[0]}</div>
              </>
            ),
          }
        : {}),
      ...(data[0].listings && data[0].listings.length > 0
        ? {
            listings: (
              <>
                <ul>
                  {listings.map((item, index) => {
                    return (
                      <>
                        <li key={index}>{item.title}</li>
                      </>
                    );
                  })}
                </ul>
              </>
            ),
          }
        : {}),
    };
    setCaretData(bookingObj);
  };
  const disapproved = async (data) => {
    setRequestId(data?.id);
    setDisapprovedModalShow(true);
  };
  const handleProcessRequest = async (data) => {
    setRequestId(data?.id);
    if (data.status === "Pending Confirmation") {
      setProcessRequestModalShow(true);
    } else {
      setPaymentModalShow(true);
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder="Filter Status"
              value={status}
              searchable={false}
              options={[
                { value: "", label: "All" },
                { value: "Pending Confirmation", label: "Pending Confirmation" },
                { value: "Pending Payment", label: "Pending Payment" },
                { value: "Approved", label: "Approved" },
                { value: "Rejected", label: "Rejected" },
                { value: "Concluded", label: "Concluded" },
              ]}
              handleChange={handleStatus}
            />
          </Col>

          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100" style={{ fontSize: "1rem" }} size="sm" type="submit" onClick={() => setAddSpotsModalShow(true)}>
              <span className="d-none d-sm-inline d-lg-inline">Create Spots</span> <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100" style={{ fontSize: "1rem" }} size="sm" type="submit" onClick={() => setShowSpotsModal(true)}>
              <span className="d-none d-sm-inline  d-lg-inline">View Spots Details</span>
            </Button>
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : featuredBookingRequests.length === 0 ? (
            <NoResult name="Featured Listings Booking Request" />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview
                data={featuredBookingRequests}
                columnNotShow={["id", "status", "listings", "trxData"]}
                caret={caret}
                caretData={caretData}
                datetimeColumn={["bookingDatetime"]}
                sortColumn={["shop_name"]}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                collapsableColumns={["notes"]}
                status={{
                  column: "status",
                  getClassName: (value) => {
                    if (value["status"] === "Approved") {
                      return "text-success";
                    } else if (value["status"] === "Pending Confirmation") {
                      return "text-warning";
                    } else if (value["status"] === "Pending Payment") {
                      return "text-info";
                    } else if (value["status"] === "Rejected") {
                      return "text-danger";
                    } else if (value["status"] === "Concluded") {
                      return "text-success";
                    }
                  },
                  format: (value) => {
                    if (value["status"] === "Approved") {
                      return "Approved";
                    } else if (value["status"] === "Pending Confirmation") {
                      return "Pending Confirmation";
                    } else if (value["status"] === "Pending Payment") {
                      return "Pending Payment";
                    } else if (value["status"] === "Rejected") {
                      return "Rejected";
                    } else if (value["status"] === "Concluded") {
                      return "Concluded";
                    }
                  },
                }}
                actionButton={{
                  column: ["status", "trxData"],
                  label: (value) => {
                    if (value["status"] === "Pending Confirmation") {
                      return "Assign Spot";
                    } else if (value["status"] === "Pending Payment" && value["trxData"].length === 0) {
                      return "Payment";
                    } else if (value["status"] === "Pending Payment" && value["trxData"].length > 0) {
                      return "Approve";
                    }
                  },
                  onClick: async (er, value) => {
                    handleProcessRequest(value);
                  },
                  variant: "outline-primary",
                  isShow: (_, row) => {
                    return row?.["status"] !== "Rejected" && row?.["status"] !== "Approved" && row?.["status"] !== "Concluded";
                  },
                }}
                disapproved={disapproved}
              />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} result(s)</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result(s)</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
        </>
        <PopUpModal title="Add Spots" show={addSpotsModalShow} onHide={() => setAddSpotsModalShow(false)}>
          <AddSpotsModal toggle={toggle} close={closeModalAdd} />
        </PopUpModal>
        <PopUpModal size="xl" title="Spots" show={showSpotsModal} onHide={() => setShowSpotsModal(false)}>
          <SpotsModal toggle={toggle} close={() => setShowSpotsModal(false)} />
        </PopUpModal>
        <PopUpModal title={`Disapprove Featured Listings Booking`} show={disapprovedModalShow} onHide={() => setDisapprovedModalShow(false)}>
          <DisapprovedModal onClose={() => setDisapprovedModalShow(false)} requestId={requestId} toggle={toggle} featured={true} />
        </PopUpModal>
        <PopUpModal size="xl" title={`Process Booking Request`} show={processRequestModalShow} onHide={() => setProcessRequestModalShow(false)}>
          <ProcessBookingRequestModal onClose={() => setProcessRequestModalShow(false)} requestId={requestId} toggle={toggle} />
        </PopUpModal>
        <PopUpModal title="Booking Payment" show={paymentModalShow} onHide={() => setPaymentModalShow(false)}>
          <FeaturedBookingPaymentModal onClose={() => setPaymentModalShow(false)} requestId={requestId} toggle={toggle} />
        </PopUpModal>
      </Container>
    </div>
  );
};

export default FeaturedListingsBookingRequests;
