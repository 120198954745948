import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import PaginationBar from "../../Components/PaginationBar";
import customloader from "../../assets/images/RollingLoader.gif";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import axios from "axios";
import { toast } from "react-toastify";
import SearchableSelect from "../../Components/SearchableSelect";
import SearchInput from "../../Components/SearchInput";
import moment from "moment/moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function RedirectionsTable() {
  let [redirections, setRedirections] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ activation_date: "ASC" });
  const [searchInput, setSearchInput] = useState("");
  const [fromDate, setFromDate] = useState(moment().subtract(100, "years")._d);
  const [toDate, setToDate] = useState(moment().add(100, "years")._d);

  const fetchAllRedirections = useApi(api.fetchAllRedirections);
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };
  const resetFilterHandle = () => {
    setSearchInput("");
    setSizeOfPages(10);
    setFromDate(moment().subtract(100, "years")._d);
    setToDate(moment().add(100, "years")._d);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const fetchRedirections = async () => {
    const res = await fetchAllRedirections.request({
      size: sizeOfPages,
      page: currentPage,
      search: searchInput,
      filters: { orderBy: orderBy, date: { from: fromDate, to: toDate } },
    });
    const data = res?.data;
    setIsLoading(false);
    if (data) {
      setRedirections(data?.data);
      setCount(data?.pagination?.no_of_items);
      if (data?.pagination?.no_of_pages === 0) {
        setNoOfPages(0);
      } else {
        setNoOfPages(data?.pagination?.no_of_pages);
        if (currentPage > data?.pagination?.no_of_pages) {
          setCurrentPage(data?.pagination?.no_of_pages);
        }
      }
    } else {
      setRedirections([]);
    }
  };
  useEffect(() => {
    fetchRedirections();
  }, [currentPage, refresh, sizeOfPages, searchInput, fromDate, toDate, toggle]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);

  const deactivateRedirections = async (row) => {
    if (row.id !== -1) {
      try {
        const res = await axios.put(process.env.REACT_APP_API_URL + `/forced-redirections/${row.id}`, {}, { withCredentials: true });
        if (res) {
          toast.success(`${res?.data?.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          refresh((prev) => !prev);
        }
      } catch (error) {
        toast.error(`${error.response.data.error.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>
          <Col xs="2" lg="4" xl="4" className="d-none d-lg-inline mb-3"></Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs="2" lg="2">
            <Link to={"/forced-redirections/add-redirection"}>
              <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit">
                <span className="d-none d-lg-inline">Add Redirections</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Link>
          </Col>
        </Row>
        <>
          <div className="pt-2">
            <Button className="mt-3" size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <img src={customloader} alt="customloader" width="100" height="100" />
            </div>
          ) : !redirections[0] ? (
            <NoResult name="Redirections" />
          ) : (
            <Row style={{ marginBottom: "-3rem" }} className="mt-3">
              <CustomizeTableview data={redirections} columnNotShow={["id"]} deactivateRedirections={deactivateRedirections} dateColumn={["Activation_Date"]} sortColumn={["Activation_Date"]} orderBy={orderBy} setOrderBy={setOrderBy} />
              <Row className="mt-5">
                <Col className="d-none d-lg-block" md="6" lg="3">
                  <span className="text-secondary fs-6">{count} results</span>
                </Col>
                <Col xs="12" lg="6">
                  <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{count} Result</span>
                </Col>
                <Col xs="6" lg="3" className="text-end mt-3"></Col>
              </Row>
            </Row>
          )}
        </>
      </Container>
    </div>
  );
}

export default RedirectionsTable;
