import React, { useState, useEffect } from "react";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import customloader from "../../assets/images/RollingLoader.gif";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import { toast } from "react-toastify";

const ProcessBookingRequestModal = ({ requestId, onClose, toggle }) => {
  const getBookingRequestById = useApi(api.getBookingRequestById, { hasCatchError: true });
  const fetchAllSpots = useApi(api.fetchAllSpots, { hasCatchError: true });

  const [bookingRequest, setBookingRequest] = useState({});
  const [spots, setSpots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amountPayable, setAmountPayable] = useState(0);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");

  const getBooking = async () => {
    try {
      const data = await getBookingRequestById.request(requestId);
      if (data.data.success) {
        const booking = data.data.success.data;
        setBookingRequest({ ...booking, listings: booking.listings.map((item) => ({ ...item, spot: null })) });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAvailableSpots = async () => {
    try {
      const response = await fetchAllSpots.request({
        status: true,
      });
      const data = response?.data.success;
      if (data) {
        setSpots(data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    setLoading(true);
    getBooking();
    fetchAvailableSpots();
    setLoading(false);
  }, [requestId]);
  const handleSpotChange = (e, item) => {
    setBookingRequest((prev) => {
      setSpots((prevSpots) =>
        prevSpots.map((spot) => {
          const listing = prev.listings.find((listing) => listing.id === item.id);
          if (spot.id === e.value) {
            return { ...spot, available_spots: spot.available_spots - 1 };
          } else if (listing.spot && spot.id === listing.spot.value) {
            return { ...spot, available_spots: spot.available_spots + 1 };
          } else {
            return spot;
          }
        })
      );

      return {
        ...prev,
        listings: prev.listings.map((listing) => {
          if (listing.id === item.id) {
            return { ...listing, spot: e };
          }
          return listing;
        }),
      };
    });
  };
  const handleSpotSubmit = async (e) => {
    e.preventDefault();
    try {
      setValidated(true);
      if (!bookingRequest?.listings?.every((listing) => listing?.spot?.value)) {
        setError("Please select one spot for each listing.");
        return;
      }
      setError("");
      const data = bookingRequest?.listings?.map((listing) => ({
        listing_id: listing.id,
        spot_id: listing?.spot?.value,
      }));
      const payload = {
        listings: data,
        amountPayable,
      };
      const res = await postapi.assignSpots(requestId, payload);
      if (res.data) {
        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
        toggle((prev) => !prev);
        onClose();
      }
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
  };

  useEffect(() => {
    const amountPayable = bookingRequest?.listings?.reduce((total, listing) => {
      if (!listing?.spot?.value) {
        return total;
      }
      const spot = spots.find((spot) => spot.id === listing.spot.value);

      if (spot) {
        const price = parseFloat(spot.price);
        return total + price;

        // const spotWeight = parseFloat(spot.spot_weight);
        // return total + price * spotWeight;
      }
      return total;
    }, 0);
    setAmountPayable(amountPayable * +bookingRequest?.booking_duration_days);
  }, [bookingRequest]);
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSpotSubmit}>
          {error && <p className="text-danger">{error}</p>}
          <Row>
            <>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12">
                <label>
                  Shop Name<span className="text-danger">*</span>
                </label>
                <Form.Control className="p-2 text-capitalize" name="province" placeholder="Plan" type="text" value={bookingRequest?.shop_name} onChange={() => {}} required disabled />
              </Form.Group>
              <Form.Group className="my-2" as={Col} lg="6" md="6" sm="12" controlId="price">
                <label>
                  Amount Payable <span className="text-danger">*</span>
                </label>
                <Form.Control value={Math.round(amountPayable)} type="number" placeholder="Rs.1000" disabled />
              </Form.Group>

              {bookingRequest?.listings?.length && (
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Listing Name</th>
                      <th>Assign Spot</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingRequest?.listings?.map((item) => (
                      <tr>
                        <td>
                          <img src={process.env.REACT_APP_URL_S3 + "/" + item?.img0} alt="listing" width="50" height="50" />
                        </td>
                        <td className="text-capitalize">{item?.listing_title}</td>
                        <td>
                          <SearchableSelect
                            selectClassName={"bg-white"}
                            placeholder="Assign Spot"
                            value={item.spot}
                            searchable={false}
                            options={spots
                              .filter((spot) => spot.available_spots > 0) // Only show available spots
                              .map((spot) => ({
                                value: spot.id,
                                label: spot.place,
                              }))}
                            handleChange={(e) => handleSpotChange(e, item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <Button className="w-100 mt-3" type="submit" disabled={!bookingRequest?.listings?.every((listing) => listing?.spot?.value)}>
                Approve
              </Button>
            </>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ProcessBookingRequestModal;
