import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import { toast } from "react-toastify";
const discountMapping = {
  percentage: { label: "Percentage", value: "percentage" },
  fixed: { label: "Fixed", value: "fixed" },
};
const AddVoucherModal = ({ toggle, close, voucherId }) => {
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [discountValueValidity, setDiscountValueValidity] = useState(false);
  const [voucher, setVoucher] = useState({
    voucher_code: "",
    discount_unit: "",
    discount_value: "",
    expiry_date: "",
    capped_amount: "",
    min_order_amount: "",
    description: "",
  });

  const minDate = useMemo(() => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1); // Add 1 day to today's date
    return nextDay.toISOString().split("T")[0];
  }, []);
  const addVoucher = useApi(postapi.AddVoucher, { hasCatchError: true });
  const fetchVoucherById = useApi(api.fetchVoucherById, { hasCatchError: true });
  const updateVoucher = useApi(postapi.UpdateVoucher, { hasCatchError: true });

  useEffect(() => {
    if (voucherId) {
      const fetchVoucher = async () => {
        const res = await fetchVoucherById.request(voucherId);
        if (res.status === 200) {
          setVoucher({ ...res.data[0], discount_unit: discountMapping[res.data[0].discount_unit], expiry_date: res.data[0].expiry_date.split("T")[0] });
        }
      };
      fetchVoucher();
    }
  }, [voucherId]);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setVoucher((prev) => ({ ...prev, [name]: value }));
  };
  const handleRequestSubmit = async (e) => {
    try {
      setIsClicked(true);
      e.preventDefault();
      setValidated(true);
      if (isClicked) {
        return;
      }
      const valid = voucher?.voucher_code && voucher?.discount_unit?.value && voucher.discount_value && voucher.expiry_date && (voucher.discount_unit?.value === "percentage" ? voucher.capped_amount : true) && voucher.min_order_amount && voucher.description ? true : false;
      if (valid) {
        setValidated(false);
        const data = {
          voucher_code: voucher.voucher_code,
          discount_unit: voucher.discount_unit?.value,
          discount_value: +voucher.discount_value,
          expiry_date: voucher.expiry_date,
          capped_amount: +voucher.capped_amount,
          min_order_amount: +voucher.min_order_amount,
          description: voucher.description,
        };
        if (voucherId) {
          const res = await updateVoucher.request(voucherId, data);
          if (res) {
            toast.success("Voucher Updated Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsClicked(false);
          }
          toggle((prev) => !prev);
          close();
        } else {
          const res = await addVoucher.request(data);
          if (res) {
            toast.success("Voucher Added Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setIsClicked(false);
          }

          toggle((prev) => !prev);
          close();
        }
      }
    } catch (error) {
      setIsClicked(false);
      setError(error.response.data.error.message);
    }
  };
  function generateRandomSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleRequestSubmit}>
        {error && <div className="text-danger text-center my-1 rounded-1 w-100">*{error}</div>}

        <Row className="">
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Voucher Code</label>
              <span className="text-danger">*</span>
              <Form.Control
                value={voucher.voucher_code}
                name="voucher_code"
                onChange={handleChange}
                min={1}
                type="string"
                placeholder="eg.D4UV0001"
                onBlur={(e) => {
                  if (e.target.value !== "") {
                    setVoucher({ ...voucher, voucher_code: e.target.value + "-" + generateRandomSixDigitNumber() });
                  }
                }}
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter Voucher Code.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>
                Discount Unit<span className="text-danger">*</span>
              </label>
              <SearchableSelect
                placeholder="Select Discount Type"
                value={voucher.discount_unit}
                name="discount_unit"
                searchable={true}
                options={[
                  { label: "Percentage", value: "percentage" },
                  { label: "Fixed", value: "fixed" },
                ]}
                handleChange={(e) => {
                  setVoucher((prev) => {
                    if (e.value === "percentage" && +prev?.discount_value > 99) {
                      setDiscountValueValidity(true);
                      return { ...prev, discount_unit: e, discount_value: "" };
                    } else {
                      return { ...prev, discount_unit: e };
                    }
                  });
                }}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Discount Unit</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Discount Value</label>
              <span className="text-danger">*</span>
              <Form.Control
                value={voucher.discount_value}
                name="discount_value"
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  const value = e.target.value;
                  if (voucher?.discount_unit?.value === "percentage") {
                    if (value > 99) {
                      setDiscountValueValidity(true);
                      return;
                    }
                    setDiscountValueValidity(false);
                    handleChange(e);
                  } else {
                    if (value.length > 8) return;
                    handleChange(e);
                  }
                }}
                min={1}
                type="number"
                placeholder="eg.100"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter Discount Value.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Expiry Date</label>
              <span className="text-danger">*</span>
              <Form.Control value={voucher.expiry_date} min={minDate} name="expiry_date" onChange={handleChange} type="date" required />
              <Form.Control.Feedback type="invalid">Please Enter Voucher Expiry Date.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Capped Amount</label>
              {voucher?.discount_unit?.value === "percentage" ? <span className="text-danger">*</span> : ""}
              <Form.Control
                value={voucher.capped_amount}
                name="capped_amount"
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  if (e.target.value.length > 8) return;
                  handleChange(e);
                }}
                type="number"
                placeholder="eg.100"
                required={voucher?.discount_unit?.value === "percentage" ? true : false}
              />
              <Form.Control.Feedback type="invalid">Please Enter Capped Amount.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="py-2">
            <Form.Group className="w-100" controlId="formBasicShopName">
              <label>Minimum Order Amount</label>
              <span className="text-danger">*</span>
              <Form.Control
                value={voucher.min_order_amount}
                name="min_order_amount"
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 8) return;
                  handleChange(e);
                }}
                type="number"
                placeholder="eg.100"
                required
              />
              <Form.Control.Feedback type="invalid">Please Enter Minimum Order Amount.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12} className="py-2">
            <Form.Group>
              <label>Description</label>
              <span className="text-danger">*</span>
              <Form.Control name="description" required value={voucher.description} as="textarea" rows={3} onChange={handleChange} />
              <Form.Control.Feedback type="invalid">Enter Description.</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {discountValueValidity && (
          <div class="alert alert-warning  text-center w-100 mt-2" role="alert">
            {"Discount Value must be less than 100%"}
          </div>
        )}
        <Row className="py-3">
          <Col>
            <Button
              className="w-100"
              onClick={handleRequestSubmit}
              disabled={isClicked || !(voucher?.voucher_code && voucher?.discount_unit?.value && voucher.discount_value && voucher.expiry_date && (voucher.discount_unit?.value === "percentage" ? voucher.capped_amount : true) && voucher.min_order_amount && voucher.description ? true : false)} // add validation here
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddVoucherModal;
