import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CardsSection from "../../Components/DashboardCards/CardsSection/CardsSection";
import StatCard from "../../Components/DashboardCards/StatCard/StatCard";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import ReactSelect from "../../Components/ReactSelect";

const DeliveryForYouOrdersStats = () => {
  const fetchDeliveryForYouOrdersStats = useApi(api.fetchDeliverForYouOrdersStats, { hasCatchError: true });
  const [shop, setShop] = useState({ value: null, label: "All Shops", name: "All Shops" });
  const dropdownShops = useApi(api.getDropdownShops);
  const [data, setData] = useState({});
  const fetchData = async () => {
    try {
      const res = await fetchDeliveryForYouOrdersStats.request(shop);
      if (res) {
        setData(res?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [shop]);
  const getShops = async (inputText) => {
    return new Promise((resolve, reject) => {
      dropdownShops
        .request(inputText)
        .then((res) => {
          const getData = res.data.map((cat) => ({
            value: cat,
            label: (
              <>
                <strong className="fs-12" name={cat.shop_name}>
                  {cat.id}-{cat.shop_name}
                </strong>
                <p className="fs-10 text-lowercase">
                  {cat.whatsapp} <br />
                  {cat.username}
                </p>
              </>
            ),
            name: cat.shop_name + " [" + cat.id + "]",
          }));
          getData.splice(0, 0, { value: null, label: "All Shops", name: "All Shops" });
          resolve(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  return (
    <Container fluid className="px-xl-5">
      <CardsSection
        sectionTitle="Platform Orders Insights"
        dropdown={
          <div style={{ minWidth: "200px" }}>
            <ReactSelect
              placeholder="Select shop"
              value={shop}
              searchable={true}
              options={getShops}
              handleChange={(e) => {
                setShop({
                  value: e.value,
                  label: e.name,
                });
              }}
              onInputChange={(newInput) => {
                return newInput;
              }}
            />
          </div>
        }
      >
        <Row className="g-1">
          <Col xl="6" lg="6" md="12" sm="12">
            <StatCard title="Total Orders" count={data?.total} />
          </Col>
          <Col xl="6" lg="6" md="6" sm="6">
            <StatCard title="Active Orders" count={data?.active} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Pending Confirmation" count={data?.pending} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Confirmed Orders" count={data?.confirmed} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Picked" count={data?.picked} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Shipped" count={data?.shipped} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Delivered" count={data?.delivered} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Returned" count={data?.returned} />
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            <StatCard title="Cancelled" count={data?.cancelled} />
          </Col>
          <Col xl="8" lg="8" md="12" sm="12">
            <StatCard title="Total worth of orders (All time)" count={data?.total_value} />
          </Col>
        </Row>
      </CardsSection>
    </Container>
  );
};

export default DeliveryForYouOrdersStats;
