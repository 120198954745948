import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import NoResult from "../../Components/NoResult";
import { useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment/moment";
import Loader from "../../Components/Loader";
import * as api from "../../api/getapi";
import useApi from "../../Hooks/useApi";
import * as postapi from "../../api/postapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import AddVoucherModal from "../../Components/DeliveryForYouOrders/AddVoucherModal";
import { dateTime } from "../../Utilities/dateTime";
import { toast } from "react-toastify";

const DeliveryForYouVouchers = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fetchAllVouchers = useApi(api.fetchAllVouchers, { hasCatchError: true });
  const deleteVoucher = useApi(postapi.deleteVoucher, { hasCatchError: true });

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: null, label: "All Vouchers" });
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [vouchers, setVochers] = useState([]);
  const [addVoucherModalShow, setAddVoucherModalShow] = useState(false);
  const [totalOrders, setTotalOrders] = useState();
  const [fromDate, setFromDate] = useState(moment().subtract(100, "years")._d);
  const [toDate, setToDate] = useState(moment().add(100, "years")._d);
  const [voucherId, setVoucherId] = useState(null);

  const searchStatus = (e) => {
    if (e.value) {
      setStatusSearch({ value: e.value, label: e.label });
    } else {
      setStatusSearch({ value: null, label: "All Vouchers" });
    }
    setCurrentPage(1);
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: null, label: "All Vouchers" });
    setFromDate(moment().subtract(100, "years")._d);
    setToDate(moment().add(100, "years")._d);
    setSizeOfPages(10);
    setCurrentPage(1);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getDeliverForYouVouchers() {
    try {
      fetchAllVouchers
        .request({
          page: currentPage,
          size: sizeOfPages,
          status: statusSearch,
          fromDate: fromDate,
          toDate: toDate,
          orderBy: orderBy,
        })
        .then((data) => {
          setIsLoading(false);
          setTotalOrders(data?.data?.pagination?.no_of_items);
          setVochers(data?.data?.vouchers);

          if (data?.data?.pagination?.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/delivery-for-you/vouchers?page=0");
            }
          } else {
            setNoOfPages(data?.data?.pagination?.no_of_pages);
            if (currentPage > data?.data?.pagination?.no_of_pages) {
              setCurrentPage(data?.data?.pagination?.no_of_pages);
              navigate("/delivery-for-you/vouchers?page=" + data?.data?.pagination?.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/delivery-for-you/vouchers?page=1");
            }
          }
        })
        .catch((err) => {
          const tempErr = err.response.data.error;

          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setVochers([]);
            setIsLoading(false);
          }
        });
    } catch (err) {
      const tempErr = err.response.data.error;

      if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        setVochers([]);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    let timeout;
    setIsLoading(true);
    getDeliverForYouVouchers();
    return () => {
      clearTimeout(timeout);
    };
  }, [fromDate, toDate, currentPage, noOfPages, sizeOfPages, statusSearch, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [fromDate, toDate, statusSearch]);
  const refreshHandle = () => {
    refresh((prev) => !prev);
  };
  const edit = async (data) => {
    setAddVoucherModalShow(true);
    setVoucherId(data["id"]);
  };
  const remove = async (data) => {
    try {
      const res = await deleteVoucher.request(data.id);
      if (res.status === 200) {
        toast.success("Voucher Deleted Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        refresh((prev) => !prev);
      }
    } catch (error) {}
  };

  return (
    <>
      <Container fluid>
        <Row className="d-flex align-items-center">
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
          <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
            <SearchableSelect
              placeholder="Filter Status"
              searchable={false}
              value={statusSearch}
              options={[
                { value: null, label: "All Vouchers" },
                { value: "valid", label: "Valid" },
                { value: "expired", label: "Expired" },
              ]}
              handleChange={searchStatus}
            />
          </Col>
          <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={(e) => setSizeOfPages(e.value)}
            />
          </Col>
          <Col xs="2" lg="2">
            <Button className="h-100 w-100 mb-3" size="sm" type="submit" onClick={() => setAddVoucherModalShow(true)}>
              <span className="d-none d-lg-inline">Add Voucher</span> <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Row>
        <div className="pt-2">
          <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
            <span className="d-lg-inline">Reset Filters</span>{" "}
          </Button>
        </div>

        {isLoading ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : vouchers?.length === 0 ? (
          <NoResult />
        ) : (
          <>
            <CustomizeTableview
              className={"mt-3"}
              data={vouchers}
              columnNotShow={["id", "is_deleted"]}
              collapsableColumns={["description"]}
              dateColumn={["created_at", "updated_at", "expiry_date"]}
              sortColumn={["created_at", "updated_at", "expiry_date", "discount_value"]}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              status={{
                column: ["expiry_date"],
                getClassName: ({ expiry_date }) => {
                  const todayDate = new Date(new Date().toDateString());
                  const expiryDate = dateTime(expiry_date?.split("T")[0]);
                  if (expiryDate <= todayDate) {
                    return "text-danger";
                  } else if (expiryDate > todayDate) {
                    return "text-success";
                  }
                },
                format: ({ expiry_date }) => {
                  const todayDate = new Date(new Date().toDateString());
                  const expiryDate = dateTime(expiry_date?.split("T")[0]);
                  if (expiryDate <= todayDate) {
                    return "Expired";
                  } else if (expiryDate > todayDate) {
                    return "Valid";
                  }
                },
              }}
              edit={{
                action: edit,
                isShow: (row) => {
                  const todayDate = new Date(new Date().toDateString());
                  const expiryDate = dateTime(row?.expiry_date?.split("T")[0]);
                  if (expiryDate >= todayDate) {
                    return true;
                  }
                },
              }}
              remove={{
                action: remove,
                isShow: (row) => {
                  const todayDate = new Date(new Date().toDateString());
                  const expiryDate = dateTime(row?.expiry_date?.split("T")[0]);
                  if (expiryDate >= todayDate) {
                    return true;
                  }
                },
              }}
            />
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-md-block" md="3" lg="3">
                <span className="text-secondary fs-6">{totalOrders} result(s)</span>
              </Col>
              <Col xs="12" md="6" lg="6">
                <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
              </Col>
              <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6">{totalOrders} result(s)</span>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <PopUpModal
        size="lg"
        title={voucherId ? "Update Voucher" : "Add Voucher"}
        show={addVoucherModalShow}
        onHide={() => {
          setVoucherId(null);
          setAddVoucherModalShow(false);
        }}
      >
        <AddVoucherModal
          toggle={refreshHandle}
          close={() => {
            setVoucherId(null);
            setAddVoucherModalShow(false);
          }}
          voucherId={voucherId}
        />
      </PopUpModal>
    </>
  );
};

export default DeliveryForYouVouchers;
