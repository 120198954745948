import React, { useEffect, useState } from "react";
import * as api from "../../api/getapi";
import * as postapi from "../../api/postapi";
import useApi from "../../Hooks/useApi";
import NoResult from "../NoResult";
import { Col, Row } from "react-bootstrap";
import PaginationBar from "../PaginationBar";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import customloader from "../../assets/images/RollingLoader.gif";
import SearchableSelect from "../SearchableSelect";
import { toast } from "react-toastify";
import PopUpModal from "../Modal";
import AddSpotsModal from "./AddSpotsModal";

const SpotsModal = ({ show, onHide }) => {
  const fetchAllSpots = useApi(api.fetchAllSpots, { hasCatchError: true });
  const deleteSpot = useApi(postapi.deleteSpot, { hasCatchError: true });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [spots, setSpots] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [refresh, toggle] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [status, setStatus] = useState({ value: null, label: "All" });
  const [bookingId, setBookingId] = useState("");
  const [editSpotsModalShow, setEditSpotsModalShow] = useState(false);

  const changePage = (page) => {
    setCurrentPage(page);
  };
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };
  const handleStatus = (e) => {
    if (e.label === "All") {
      setStatus({ value: null, label: "All" });
      setCurrentPage(1);
    } else {
      setStatus({ value: e.value, label: e.label });
      setCurrentPage(1);
    }
  };
  const getSpots = async () => {
    try {
      const response = await fetchAllSpots.request({
        size: sizeOfPages,
        page: currentPage,
        orderBy: orderBy,
        status: status.value,
      });
      const data = response?.data.success;
      if (data) {
        setSpots(data.data);
        setCount(data?.pagination?.totalCount);
        if (data?.pagination?.totalPages === 0) {
          setNoOfPages(0);
        } else {
          setNoOfPages(data?.pagination?.totalPages);
          if (currentPage > data?.pagination?.totalPages) {
            setCurrentPage(data?.pagination?.totalPages);
          }
        }
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  useEffect(() => {
    setLoading(true);
    getSpots();
    setLoading(false);
  }, [show, refresh, currentPage, sizeOfPages, orderBy, status]);
  const remove = async (data) => {
    try {
      const response = await deleteSpot.request(data?.id);
      if (response.status === 200) {
        toggle((prev) => !prev);
        toast.success(response?.data?.message, {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const edit = (data) => {
    setBookingId(data?.id);
    setEditSpotsModalShow(true);
  };
  const closeModalEdit = () => {
    setEditSpotsModalShow(false);
    toggle((prev) => !prev);
    setBookingId("");
  };
  return (
    <>
      <Row className="">
        <Col>
          <SearchableSelect
            selectClassName={"bg-white"}
            placeholder="Filter Status"
            value={status}
            searchable={false}
            options={[
              { value: null, label: "All" },
              { value: "1", label: "Available Spots" },
            ]}
            handleChange={handleStatus}
          />
        </Col>
        <Col xs="6" lg="6" className="">
          <SearchableSelect
            selectClassName={"bg-white"}
            placeholder={`Showing ${sizeOfPages}`}
            defaultValue={`Showing ${sizeOfPages}`}
            value={`Showing ${sizeOfPages}`}
            searchable={false}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "30", label: "30" },
            ]}
            handleChange={handlePageSize}
          />
        </Col>
      </Row>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : spots.length === 0 ? (
        <NoResult name="Spots" />
      ) : (
        <Row className="mt-5">
          <CustomizeTableview data={spots} columnNotShow={["id"]} dateColumn={["created_at", "updated_at"]} orderBy={orderBy} setOrderBy={setOrderBy} refresh={refresh} toggle={toggle} edit={edit} remove={remove} />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} Result(s)</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result(s)</span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </Row>
      )}
      <PopUpModal title={bookingId ? "Edit Spot" : "Add Spot"} show={editSpotsModalShow} onHide={() => setEditSpotsModalShow(false)}>
        <AddSpotsModal toggle={toggle} close={closeModalEdit} bookingId={bookingId} />
      </PopUpModal>
    </>
  );
};

export default SpotsModal;
