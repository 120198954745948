import { useEffect, useState } from "react";
import { Container, Row, Modal, Col, Button, Tabs, Tab } from "react-bootstrap";
import SearchInput from "../../Components/SearchInput";
import SearchableSelect from "../../Components/SearchableSelect";
import Payments from "../../Components/Payment/payment";
import moment from "moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
const Payment = () => {
  const [searchInput, setSearchInput] = useState("");
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [refresh, toggle] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(100, "years")._d);
  const [toDate, setToDate] = useState(moment().add(100, "years")._d);

  // shops on  payment

  const [orderBy, setOrderBy] = useState({ shop_id: "ASC" });

  // Search Products
  const searchFilter = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearchInput("");
    } else {
      setSearchInput(e.target.value);
    }
  };

  const resetFilterHandle = () => {
    setSearchInput("");
    setSearchInput("");
    setFromDate(moment().subtract(100, "years")._d);
    setToDate(moment().add(100, "years")._d);
  };
  useEffect(() => {}, [refresh]);
  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          <Col xs="2" lg="2" className="d-none d-lg-inline">
            <SearchInput searchlogo handleChange={searchFilter} value={searchInput} />
          </Col>

          <Col xs="6" lg="2" className="">
            <SearchableSelect
              selectClassName={"bg-white"}
              placeholder={`Showing ${sizeOfPages}`}
              defaultValue={`Showing ${sizeOfPages}`}
              value={`Showing ${sizeOfPages}`}
              searchable={false}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
              ]}
              handleChange={handlePageSize}
            />
          </Col>
          <Col xs="2" lg="2">
            <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Col>
        </Row>

        <>
          <div className="pt-2">
            <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
              <span className="d-none d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          <Row style={{ marginBottom: "-3rem" }} className="mt-3">
            <Col>
              <Payments
                search={searchInput}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                sizeOfPage={sizeOfPages}
                refresh={refresh}
                // toggle={toggle}
                dates={{ from: fromDate, to: toDate }}
              />
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
};
export default Payment;
